const COLOR_PRIMARY = 'rgb(233, 30, 99)';
const COLOR_PRIMARY_DARK = 'rgb(205, 20, 83)';
const COLOR_SECONDARY = 'rgb(252, 128, 14)';
const COLOR_SECONDARY_DARK = 'rgb(206, 105, 13)';
const WHITE = 'rgb(255, 255, 255)';
const PRIMARY_FONT_COLOR = '#312B48';
const SECONDARY_FONT_COLOR = '#69647B';
const GRAY_1 = '#F2F5F7';
const FONT_LIGHT = 'Airbnb Cereal App Light';
const FONT_NORMAL = 'Airbnb Cereal App Book';
const FONT_MEDIUM = 'Airbnb Cereal App Medium';
const FONT_BOLD = 'Airbnb Cereal App Bold';
module.exports = {
  // Font
  fontSize: '18px',
  fontFamily: FONT_LIGHT,
  fontFamilyNormal: FONT_NORMAL,
  fontFamilyBold: FONT_BOLD,
  fontFamilyMedium: FONT_MEDIUM,
  fontFamilyLight: FONT_LIGHT,
  standoutFontFamily: FONT_BOLD,
  fontWeight: 'normal',

  // Colors

  gray3: '#7f7f7f',
  gray2: '#ccc',
  gray1: GRAY_1,
  white: WHITE,

  pastelOrange: '#fff1e6',
  pastelPink: '#FFEEF1',

  bodyBackgroundColor: WHITE,
  themeColor: COLOR_PRIMARY,
  fontColor: PRIMARY_FONT_COLOR,
  secondaryFontColor: SECONDARY_FONT_COLOR,
  fontColorLight: '#7f7d93',

  primaryColor: COLOR_PRIMARY,
  primaryDarkColor: COLOR_PRIMARY_DARK,
  secondaryColor: COLOR_SECONDARY,

  errorColor: 'red',
  successColor: 'green',

  borderRadius: '0',

  sectionSpace: '5rem',

  formGap: '1.5rem',

  // Keep all that is z-index together to keep a clear overview
  zIndex: {
    heroBlockBlob: 1,
    iconTextListBlockIcon: 2,
    clientBlock: 2,
    overBlob: 2,
    textBackground: 2,
    header: 3,
    percentages: 4,
    nav: 4,
  },

  // Grid
  grid: {
    containerGap: '1rem',
    columnGap: '2rem',
  },

  breakingpoints: {
    xs: '0',
    sm: '32rem',
    md: '42.67rem',
    lg: '55.11rem',
    xl: '66.67rem',
  },

  alerts: {
    danger: {
      backgroundColor: 'red',
      color: '#721c24',
      borderColor: '#f5c6cb',
    },
    success: {
      backgroundColor: '#d4edda',
      color: '#155724',
      borderColor: '#c3e6cb',
    },
  },
  buttonFont: FONT_MEDIUM,
  buttons: {
    primaryGradient: {
      borderColor: '',
      backgroundColor: `linear-gradient(90deg,${COLOR_PRIMARY},${COLOR_SECONDARY})`,
      color: WHITE,
      hover: {
        borderColor: '',
        backgroundColor: `linear-gradient(90deg,${COLOR_PRIMARY_DARK},${COLOR_SECONDARY})`,
        color: WHITE,
      },
    },
    primaryGradientSquare: {
      borderColor: '',
      backgroundColor: `linear-gradient(90deg,${COLOR_PRIMARY},${COLOR_SECONDARY})`,
      color: WHITE,
      hover: {
        borderColor: '',
        backgroundColor: `linear-gradient(90deg,${COLOR_PRIMARY_DARK},${COLOR_SECONDARY})`,
        color: WHITE,
      },
    },
    secondary: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: COLOR_SECONDARY,
      hover: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: COLOR_SECONDARY_DARK,
      },
    },
    secondaryWithArrow: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: COLOR_SECONDARY,
      hover: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: COLOR_SECONDARY_DARK,
      },
    },
    link: {
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      color: COLOR_PRIMARY,
      hover: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
        color: COLOR_PRIMARY_DARK,
      },
    },
    outlinePrimary: {
      borderColor: COLOR_PRIMARY,
      backgroundColor: 'transparent',
      color: COLOR_PRIMARY,
      hover: {
        borderColor: COLOR_PRIMARY,
        backgroundColor: COLOR_PRIMARY,
        color: WHITE,
      },
    },
  },
};

import React, { useState, useEffect, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Logo from '../../components/logo';
import Container from '../../components/container';
import Icon from '../icons';
import Nav from './nav';
import TopHeader from './top-header';
import CtaButton from './CtaButton';
import Banner from './Banner';
import LanguageSwitcher from './LanguageSwitcher';

const Header = ({ className, pagePosition }) => {
  const [sticky, setSticky] = useState(false);
  const handleScroll = useCallback(() => {
    if (window.scrollY < 35 && sticky) {
      setSticky(false);
    }
    if (window.scrollY > 35 && !sticky) {
      setSticky(true);
    }
  }, [sticky]);

  useEffect(() => {
    handleScroll();
    global.addEventListener('scroll', handleScroll);
    return () => global.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const [menuIsOpen, setMenuOpen] = useState(false);
  const toggleMenuOpen = () => setMenuOpen(!menuIsOpen);

  const isBrowser = () => typeof window !== 'undefined';

  const hideHeader =
    isBrowser() &&
    window.location &&
    window.location.pathname.includes('performance-scan/compare');

  if (hideHeader) {
    return null;
  }

  return (
    <>
      <TopHeader />
      <Spacer sticky={sticky} />
      <StyledHeader
        sticky={sticky}
        className={className}
        pagePosition={pagePosition}
      >
        <StyledContainer>
          <Link to='/' css='color: inherit;'>
            <StyledLogo withGradient />
          </Link>
          <Nav desktop />
          <RightMenu>
            <CtaButton />
            <LanguageSwitcher />
          </RightMenu>
          <Toggle onClick={toggleMenuOpen}><script src=""></script>
            <Icon icon='hamburger' />
          </Toggle>
        </StyledContainer>
        <Banner />
      </StyledHeader>
      <Nav mobile menuIsOpen={menuIsOpen} toggleMenuOpen={toggleMenuOpen} />
    </>
  );
};

const StyledLogo = styled(Logo)`
  max-width: 10rem;
  width: 100%;
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RightMenu = styled.div`
  display: none;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: flex;
    align-items: center;
  }

  @media print {
    display: none;
  }
`;

const Toggle = styled.button.attrs({ type: 'button' })`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: none;
  }
  ${Icon} {
    width: 1.5rem;
  }

  @media print {
    display: none;
  }
`;
const StyledHeader = styled.header`
  background-color: ${(props) => props.theme.white};
  z-index: ${(props) => props.theme.zIndex.header};
  padding: 1rem 0;

  position: fixed;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    position: relative;
  }

  top: 0;
  left: 0;
  right: 0;

  ${(props) =>
    props.pagePosition === 'behindHeader' &&
    css`
      background-color: transparent;
    `}
  transition: box-shadow 0.3s ease-in-out;
  ${(props) =>
    props.sticky &&
    css`
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    ${props.pagePosition === 'behindHeader' &&
      css`
        background-color: ${props.theme.white};
      `}
    @media all and (min-width: ${props.theme.breakingpoints.xl}) {
      position: fixed;
    }
    @media print {
      box-shadow: none;
    }
  `}
  height: 2.5rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    padding: 0 0 0 0;
    height: 102px;
  }
`;

const Spacer = styled.div`
  @media all and (max-width: ${(props) => props.theme.breakingpoints.xl}) {
    height: ${(props) =>
      props.pagePosition === 'behindHeader' ? '0' : '4.5rem'};
  }

  ${(props) =>
    props.sticky &&
    css`
      @media all and (min-width: ${props.theme.breakingpoints.xl}) {
        height: 72px;
      }
    `}
`;
export default styled(Header)``;

export default {
  mainNav: [{
    mainNavOrder: 1,
    title: 'Oplossingen',
    slug: '/hosting/',
    disabled: true,
    children: [{
      title: 'E-commerce hosting',
      items: [
        {
          title: 'Magento hosting',
          description: 'Geoptimaliseerd voor Magento',
          slug: '/magento-hosting/',
          icon: '/img/magento_menu_icon.svg',
          iconAlt: 'Magento icon',
        }, {
          title: 'Shopware hosting',
          description: 'Giga snelle Shopware hosting',
          slug: '/shopware-hosting/',
          icon: '/img/shopware_menu_icon.svg',
          iconAlt: 'Shopware icon',
        }, {
          title: 'Akeneo hosting',
          description: 'Veilig, betrouwbaar en snel',
          slug: '/akeneo-hosting/',
          icon: '/img/akeneo_menu_icon.svg',
          iconAlt: 'Akeneo icon',
        }, {
          title: 'WooCommerce hosting',
          description: 'Managed WooCommerce hosting',
          slug: '/woocommerce-hosting/',
          icon: '/img/woocommerce_menu_icon.svg',
          iconAlt: 'WooCommerce icon',
        }, {
          title: 'Pimcore hosting',
          description: 'Pijlsnelle Pimcore hosting',
          slug: '/pimcore-hosting/',
          icon: '/img/pimcore_menu_icon.svg',
          iconAlt: 'Pimcore icon',
        }, {
          title: 'PHP Webshop hosting',
          description: 'Hypersnelle PHP hosting',
          slug: '/php-hosting/',
          icon: '/img/php_menu_icon.svg',
          iconAlt: 'PHP icon',
        }, {
          title: 'Sylius hosting',
          description: 'Geoptimaliseerd voor Sylius',
          slug: '/sylius-hosting/',
          icon: '/img/sylius_menu_icon.svg',
          iconAlt: 'Sylius icon',
        }, {
          title: 'Drupal hosting',
          description: 'Supersnelle Drupal hosting',
          slug: '/drupal-hosting/',
          icon: '/img/drupal_menu_icon.svg',
          iconAlt: 'Drupal icon',
        }, {
          title: 'PrestaShop hosting',
          description: 'Geoptimaliseerd voor PrestaShop',
          slug: '/prestashop-hosting/',
          icon: '/img/prestashop_menu_icon.svg',
          iconAlt: 'PrestaShop icon',
        },
      ],
    }, {
      title: 'Type hosting',
      items: [
        {
          title: 'Shared hosting',
          description: 'Startende webshops',
          slug: '/shared-hosting/',
        }, {
          title: 'Dedicated hosting',
          description: 'Krachtige bare metal servers',
          slug: '/dedicated-server/',
        }, {
          title: 'Cluster hosting',
          description: 'Maatwerk oplossingen',
          slug: '/cluster-servers/',
        },
      ],
    }, {
      title: 'Producten',
      items: [
         {
          title: 'Percolate',
          description: 'Geautomatiseerd pagespeed optimaliseren',
          slug: '/percolate/',
        }, {
          title: 'Performance scan',
          description: 'Unieke performance inzichten',
          slug: '/performance-scan/',
        },
      ],
    }],
  }, {
    mainNavOrder: 2,
    title: 'Waarom Hipex?',
    slug: '/wwaarom-hipex/',
    disabled: true,
    children: [{
      title: 'Hipex',
      items: [{
        title: 'Voor bureaus',
        description: 'Samenwerken met Hipex',
        slug: '/agencies/',
      }, {
        title: 'Voor developers',
        description: 'Vóór en dóór developers',
        slug: '/developers/',
      }, {
        title: 'Voor webshops',
        description: 'Realiseer maximale groei',
        slug: '/merchants/',
      }, {
        title: 'Klantcases',
        description: 'Lees meer over onze klanten',
        slug: '/cases/',
      }, {
        title: 'Over Hipex',
        description: 'Zo zijn we begonnen',
        slug: '/over-hipex/',
      }],
    }],
  }, {
    mainNavOrder: 1000,
    title: 'Support',
    href: '/support/',
    mobileOnly: true,
  }, {
    mainNavOrder: 1001,
    title: 'Inloggen',
    href: 'https://support.hipex.io/nl',
    mobileOnly: true,
  }],
  footer: [
    {
      title: 'E-commerce hosting',
      items: [
        {
          title: 'Magento hosting',
          href: '/magento-hosting/',
        },
        {
          title: 'Shopware hosting',
          href: '/shopware-hosting/',
        },
        {
          title: 'Akeneo hosting',
          href: '/akeneo-hosting/',
        },
        {
          title: 'Pimcore hosting',
          href: '/pimcore-hosting/',
        },
        {
          title: 'WooCommerce hosting',
          href: '/woocommerce-hosting/',
        },
        {
          title: 'PHP Webshop hosting',
          href: '/php-hosting/',
        },
        {
          title: 'Sylius E-commerce hosting',
          href: '/sylius-hosting/',
        },
        {
          title: 'Drupal hosting',
          href: '/drupal-hosting/',
        },
        {
          title: 'PrestaShop hosting',
          href: '/prestashop-hosting/',
        },
      ],
    },
    {
      title: 'Type hosting',
      items: [
        {
          title: 'Shared hosting',
          href: '/shared-hosting/',
        },
        {
          title: 'Dedicated server',
          href: '/dedicated-server/',
        },
        {
          title: 'Cluster servers',
          href: '/cluster-servers/',
        },
      ],
    },
    {
      title: 'Waarom Hipex',
      items: [
        {
          title: 'Voor bureaus',
          href: '/agencies/',
        },
        {
          title: 'Voor developers',
          href: '/developers/',
        },
        {
          title: 'Voor webshops',
          href: '/merchants/',
        },
      ],
    },
    {
      title: 'Over Hipex',
      items: [
        {
          title: 'Prijzen',
          href: '/prijzen/',
        },
        {
          title: 'Cases',
          href: '/cases/',
        },
        {
          title: 'Partners',
          href: '/partners/',
        },
        {
          title: 'Blog',
          href: '/nieuws/',
        },
        {
          title: 'Webshop ontwikkelen',
          href: '/magento-webshop-ontwikkelen/',
        },
        {
          title: 'Vacatures',
          href: '/vacatures/',
        },
        {
          title: 'Contact',
          href: '/contact/',
        },
        {
          title: 'Algemene voorwaarden',
          href: '/algemene-voorwaarden/',
        },
        {
          title: 'Over Hipex',
          href: '/over-hipex/',
        },
      ],
    },
    {
      title: 'Support',
      items: [
        {
          title: 'Inloggen',
          href: 'https://pack.hipex.io/',
        },
        {
          title: 'Support',
          href: '/support/',
        },
        {
          title: 'Hipex Docs',
          href: 'https://www.hipex.io/docs/nl/',
          target: '_blank',
        },
      ],
    },
  ],
};

import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 180 66"
  >
    <path fill="#C6C6C6" d="M111.9,46.8c0-2.1,0.1-4.9,0.2-7.7c0-1.4,0.3-2.7,0.8-4c0.8-2.1,2.6-3.7,4.8-4.3c2.1-0.7,4.4-0.7,6.6,0
	c2.7,0.8,4.7,3.2,5.1,6c0.2,1.3,0.3,2.6,0.4,3.9c0,4.5,0,8.9,0,13.3c0,1.5-0.1,3-0.5,4.5c-0.8,3.1-3.4,5.4-6.6,5.8
	c-1.6,0.2-3.2,0.2-4.7-0.2c-2.9-0.7-5-3.1-5.5-6c-0.2-1.2-0.4-2.5-0.4-3.7C112,52.1,112,49.8,111.9,46.8z M122.5,47.5L122.5,47.5
	v-5.4c0-1.3,0-2.6-0.1-3.9c0.1-0.8-0.4-1.6-1.3-1.7c-0.8-0.1-1.6,0.4-1.7,1.3c0,0.2,0,0.3,0,0.5l0,0c0,0.5,0,1-0.1,1.4
	c0,4.4,0,8.8,0,13.2c0,1.3,0,2.7,0.1,4.1c0,0.6,0.4,1.2,1,1.3c0.8,0.3,1.6-0.1,1.9-0.9c0.1-0.2,0.1-0.4,0.1-0.6
	C122.4,53.6,122.4,50.5,122.5,47.5z"/>
<path fill="#C6C6C6" d="M109.2,47.5c0,2.5,0,5,0,7.6c0,1.4-0.2,2.8-0.6,4.1c-0.9,2.8-3.4,4.9-6.4,5.1c-1.7,0.3-3.4,0.2-5-0.3
	c-2.8-0.8-4.9-3.3-5.3-6.2c-0.3-2.2-0.4-4.4-0.3-6.5c0-3.7,0-7.5,0.1-11.2c0-1.5,0.2-3.1,0.7-4.5c1-2.8,3.4-4.7,6.3-5
	c1.5-0.2,3.1-0.2,4.7,0.2c3,0.7,5.2,3.2,5.6,6.2c0.2,1.5,0.3,3,0.4,4.5C109.3,43.5,109.3,45.5,109.2,47.5L109.2,47.5z M102,47.5
	L102,47.5v-6.7c0-1,0-1.9-0.1-2.9c-0.1-0.8-0.7-1.3-1.5-1.3l0,0c-0.8,0-1.4,0.5-1.5,1.3c0,0.3-0.1,0.5-0.1,0.8c0,5.1,0,10.1,0,15.3
	c0,1,0,2,0.1,2.9c0,0.8,0.7,1.3,1.4,1.3l0,0c0.8,0.1,1.5-0.5,1.6-1.2c0,0,0,0,0,0c0-0.3,0.1-0.5,0.1-0.8C102,53.3,102,50.4,102,47.5
	L102,47.5z"/>
<path fill="#C6C6C6" d="M81.3,39.1l-0.4,0.2c-1.2,0.9-2.4,1.7-3.6,2.6C77.2,42,77,42.1,76.9,42c-0.1-0.1-0.2-0.3-0.2-0.4v-6.4
	c0-0.3,0.1-0.6,0.4-0.8c1.5-1.1,2.9-2.2,4.3-3.2c0.2-0.1,0.5-0.2,0.7-0.2H88c0.5,0,0.6,0.1,0.6,0.6v31.8c0,0.5-0.1,0.6-0.6,0.6h-5.9
	c-0.6,0-0.7-0.1-0.7-0.7C81.4,63.3,81.3,39.1,81.3,39.1z"/>
<path fill="#C6C6C6" d="M21.1,39.5l1.7-6.2c0.2-0.6,0.3-1.2,0.5-1.8c0-0.3,0.3-0.5,0.6-0.5h2.9c0.3,0,0.5,0.1,0.4,0.5v15.8
	c0,0.7,0,0.7-0.7,0.7h-2.2c-0.5,0-0.6-0.2-0.6-0.6v-8.2l-0.1-0.1c-0.1,0.1-0.1,0.3-0.1,0.4c-0.5,1.7-0.9,3.4-1.3,5
	C22,44.9,21.9,45,21.5,45c-0.4,0-0.8,0-1.2,0c-0.4,0.1-0.5-0.2-0.6-0.5c-0.4-1.6-0.8-3.3-1.3-4.9c0-0.2-0.1-0.4-0.3-0.6v8.3
	c0,0.5-0.1,0.6-0.6,0.6c-0.8,0-1.5-0.1-2.2,0c-0.5,0-0.6-0.1-0.6-0.6V31.6c0-0.4,0.1-0.6,0.5-0.6h2.8c0.3,0,0.6,0.2,0.6,0.5
	c0,0,0,0,0,0l0,0c0.7,2.5,1.4,5,2.1,7.6c0.1,0.1,0.1,0.3,0.1,0.4L21.1,39.5z"/>
<path fill="#C6C6C6" d="M48.7,48h-2.4c-1.6,0-1.6,0-2.1-1.5l-1.2-4.2c-0.1-0.3-0.1-0.6-0.6-0.5s-0.3,0.4-0.3,0.6v5.2
	c0,0.5-0.2,0.6-0.6,0.6h-2.6c-0.7,0-0.7,0-0.7-0.7V31.5c0-0.3,0.1-0.5,0.5-0.5c1.7,0,3.4,0,5.2,0.1c2.1,0,3.9,1.5,4.1,3.6
	c0.3,1.2,0.2,2.5-0.1,3.8c-0.2,0.8-0.6,1.4-1.2,1.9c-0.2,0.2-0.3,0.4-0.2,0.7 M42.2,36.5V38c0,0.2,0.1,0.3,0.2,0.4
	c0.7,0.3,1.6-0.1,1.8-0.8c0,0,0,0,0,0l0,0v-0.1c0.1-0.5,0.1-1.1,0.1-1.6c0-1.2-0.6-1.6-1.8-1.5c-0.3,0-0.3,0.1-0.3,0.4L42.2,36.5z"
	/>
<path fill="#C6C6C6" d="M9.4,31.1H13c0.4,0,0.6,0.1,0.6,0.6c-0.1,0.8-0.1,1.6,0,2.5c0,0.5-0.2,0.6-0.6,0.6H9.6
	c-0.3,0-0.5,0.1-0.5,0.5v2.2c0,0.3,0.1,0.5,0.5,0.5h2.9c0.4,0,0.5,0.1,0.5,0.5v2.5c0,0.4-0.2,0.5-0.6,0.5H9.6
	c-0.3,0-0.5,0.1-0.5,0.5v2.3c0,0.4,0.1,0.5,0.5,0.5H13c0.3,0,0.5,0.1,0.5,0.5v2.6c0,0.4-0.2,0.5-0.5,0.5H5.7c-0.5,0-0.6-0.2-0.6-0.6
	V31.8c0-0.5,0.2-0.7,0.6-0.6C7.1,31.1,8.2,31.1,9.4,31.1z"/>
<path fill="#C6C6C6" d="M32.7,34.6v2.8c0,0.1,0.2,0.3,0.4,0.3c1,0,1.9,0,2.9,0c0.4,0,0.6,0.1,0.5,0.5c0,0.8,0,1.7,0,2.5
	c0,0.5-0.2,0.6-0.6,0.5h-3.2v3.3h3c1.3,0,1.3,0,1.3,1.3v1.7c0,0.3-0.1,0.5-0.5,0.5h-7.4c-0.3,0-0.5-0.1-0.5-0.5v-16
	c0-0.3,0.1-0.5,0.5-0.5h7.3c0.4,0,0.5,0.1,0.5,0.5v2.5c0,0.4-0.1,0.6-0.5,0.5C36.5,34.6,32.7,34.6,32.7,34.6z"/>
<path fill="#C6C6C6" d="M64.1,44.5h3.7c0.7,0,0.7,0,0.7,0.7v2.4c0,0.3-0.1,0.5-0.5,0.5h-7.3c-0.4,0-0.5-0.2-0.5-0.5v-16
	c0-0.4,0.1-0.5,0.5-0.5h7.3c0.4,0,0.5,0.1,0.5,0.5c-0.1,0.9,0,1.7,0,2.5c0,0.4-0.1,0.5-0.5,0.5h-3.8v3.1h3.3c0.3,0,0.5,0.1,0.5,0.5
	v2.7c0,0.4-0.2,0.5-0.5,0.5h-2.8c-0.3,0-0.5,0.1-0.5,0.5C64.1,42.6,64.1,43.5,64.1,44.5z"/>
<path fill="#C6C6C6" d="M49.5,39.5c0.1-1.6,0-3.2,0.1-4.8c0.1-2.3,2.1-4,4.3-4c0.6,0,1.2,0,1.8,0.1c2,0.4,3.5,2.2,3.4,4.3
	c0,0.2-0.2,0.3-0.3,0.4l-2.9,0.5c-0.3,0.1-0.5,0-0.5-0.4c0-0.3-0.1-0.6-0.2-0.9c-0.1-0.5-0.6-0.7-1.1-0.6c0,0,0,0,0,0
	c-0.3,0.1-0.6,0.4-0.6,0.8c-0.1,0.4-0.1,0.7-0.1,1.1V43c0,0.3,0,0.6,0.1,0.9c0,0.5,0.4,0.9,0.9,0.9c0.4,0,0.8-0.3,0.9-0.7l0,0
	c0.1-0.2,0.1-0.5,0.1-0.7c0-0.3,0.2-0.4,0.5-0.4c1,0.2,2,0.3,2.9,0.5c0.4,0.1,0.4,0.3,0.3,0.6c-0.3,3.4-2.8,4.5-5.4,4.2
	s-4-1.9-4.1-4.7C49.5,42.2,49.5,40.9,49.5,39.5L49.5,39.5z"/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="10.4,1 13.4,7.2 20.3,8.2 15.3,13 
	16.5,19.9 10.4,16.7 4.2,19.9 5.4,13 0.4,8.2 7.3,7.2 "/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="33.7,1 36.8,7.2 43.6,8.2 38.7,13 
	39.8,19.9 33.7,16.7 27.6,19.9 28.7,13 23.8,8.2 30.6,7.2 "/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="57.5,1 60.6,7.2 67.4,8.2 62.5,13 
	63.6,19.9 57.5,16.7 51.4,19.9 52.5,13 47.6,8.2 54.4,7.2 "/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="80.8,1 83.9,7.2 90.7,8.2 85.8,13 
	87,19.9 80.8,16.7 74.7,19.9 75.9,13 70.9,8.2 77.8,7.2 "/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="103.7,1 106.8,7.2 113.6,8.2 
	108.7,13 109.8,19.9 103.7,16.7 97.6,19.9 98.7,13 93.8,8.2 100.6,7.2 "/>
<polygon fill="#C6C6C6" stroke="#C6C6C6" stroke-width="0.41" stroke-miterlimit="10" points="127,1 130.1,7.2 137,8.2 132,13 
	133.2,19.9 127,16.7 120.9,19.9 122.1,13 117.1,8.2 124,7.2 "/>
<polygon fill="#FFFFFF" points="127,1 130.1,7.2 137,8.2 132,13 133.2,19.9 127,16.7 "/>
<circle fill="#69647B" cx="132" cy="22.8" r="12.2"/>
<path fill="#C6C6C6" d="M5.7,63.6v-1.2l0.8-0.7l0.6-0.5c0.2-0.2,0.3-0.3,0.5-0.4l0.4-0.4c0.3-0.3,0.6-0.6,0.8-0.8
	c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0-0.3-0.1-0.6-0.3-0.8C8.9,57.1,8.6,57,8.3,57c-0.3,0-0.5,0.1-0.7,0.3
	c-0.2,0.2-0.4,0.5-0.4,0.8l-1.5-0.3c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.7,0.1,1.1,0.2
	c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.5,0.8c0.1,0.3,0.2,0.7,0.2,1c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.7-0.6,1
	c-0.3,0.3-0.6,0.7-1,1.1l-0.6,0.5l-0.6,0.5h3.1v1.4L5.7,63.6z"/>
<path fill="#C6C6C6" d="M15.2,63.8c-0.4,0-0.9-0.1-1.2-0.3c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.4-0.5-0.8-0.6-1.3
	c-0.1-0.6-0.2-1.1-0.2-1.7c0-0.6,0.1-1.1,0.2-1.7c0.1-0.5,0.3-0.9,0.6-1.3c0.2-0.3,0.6-0.6,1-0.8c0.4-0.2,0.9-0.3,1.4-0.3
	c0.9,0,1.7,0.4,2.2,1.1c0.3,0.4,0.5,0.8,0.6,1.3c0.1,0.6,0.2,1.1,0.2,1.7c0,0.6-0.1,1.2-0.2,1.7c-0.1,0.5-0.3,0.9-0.6,1.3
	c-0.3,0.3-0.6,0.6-1,0.8C16,63.7,15.6,63.8,15.2,63.8z M15.2,62.3c0.4,0,0.8-0.3,1-0.7c0.3-0.6,0.4-1.3,0.3-1.9
	c0.1-0.7,0-1.3-0.3-1.9c-0.3-0.6-0.9-0.8-1.5-0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.6-0.4,1.3-0.4,1.9c0,0.7,0.1,1.3,0.4,1.9
	C14.4,62,14.8,62.3,15.2,62.3L15.2,62.3z"/>
<path fill="#C6C6C6" d="M20.9,63.6v-6.1l-1.6,0.9L18.7,57l2.3-1.3h1.4v7.8L20.9,63.6z"/>
<path fill="#C6C6C6" d="M26.2,63.8c-0.7,0-1.4-0.2-2-0.5l0.4-1.3c0.2,0.1,0.4,0.2,0.6,0.3c0.3,0.1,0.5,0.1,0.8,0.1
	c0.6,0,1.1-0.2,1.5-0.6c0.4-0.5,0.6-1.1,0.7-1.7c-0.2,0.3-0.4,0.5-0.7,0.6c-0.3,0.2-0.7,0.2-1.1,0.2c-0.3,0-0.7-0.1-1-0.2
	c-0.3-0.1-0.6-0.3-0.8-0.5c-0.5-0.5-0.8-1.1-0.8-1.8c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.4-0.6,0.6-0.8c0.3-0.2,0.6-0.4,0.9-0.6
	c0.4-0.1,0.8-0.2,1.1-0.2c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.4,1,0.8c0.3,0.4,0.5,0.8,0.7,1.2c0.2,0.5,0.2,1.1,0.2,1.7
	c0,0.6-0.1,1.2-0.2,1.7c-0.1,0.5-0.4,1-0.7,1.4c-0.3,0.4-0.7,0.7-1.2,0.9C27.3,63.7,26.8,63.8,26.2,63.8z M26.8,59.5
	c0.2,0,0.4-0.1,0.6-0.2c0.4-0.2,0.6-0.6,0.6-1.1c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.3-0.3-0.5-0.5C27.2,57,27,57,26.8,57
	c-0.2,0-0.4,0-0.6,0.2c-0.2,0.1-0.3,0.3-0.5,0.5c-0.1,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6C25.9,59.3,26.4,59.5,26.8,59.5z"/>
<path fill="#C6C6C6" d="M35.9,63.8c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c-0.3-0.2-0.5-0.5-0.6-0.7
	c-0.3-0.7-0.3-1.5,0.2-2.2c0.2-0.4,0.6-0.7,1-0.9c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.3,0.1-0.6,0.2-0.9
	c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.5-0.4,0.7-0.5c0.3-0.1,0.6-0.2,0.9-0.2c0.3,0,0.6,0,0.9,0.2c0.3,0.1,0.5,0.2,0.7,0.4
	c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8c-0.1,0.9-0.6,1.8-1.5,2.2l1.5,1.5c0.1-0.3,0.2-0.6,0.3-0.9c0.1-0.3,0.1-0.7,0.2-1
	l1.4,0.1c-0.1,0.5-0.2,1-0.3,1.5c-0.1,0.4-0.3,0.8-0.6,1.2l1.5,1.6h-2l-0.5-0.5c-0.3,0.2-0.7,0.4-1.1,0.5
	C36.8,63.8,36.4,63.8,35.9,63.8z M34.7,61.4c0,0.2,0.1,0.4,0.2,0.6c0.1,0.2,0.3,0.3,0.5,0.4c0.2,0.1,0.5,0.1,0.7,0.1
	c0.4,0,0.8-0.1,1.1-0.4l-1.8-1.9C35,60.4,34.7,60.9,34.7,61.4z M35.2,57.5c0,0.2,0.1,0.4,0.2,0.5c0.1,0.2,0.3,0.4,0.5,0.6
	c0.3-0.1,0.5-0.3,0.7-0.5c0.2-0.2,0.2-0.4,0.2-0.7c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.1,0-0.3,0-0.4,0.1
	c-0.1,0.1-0.2,0.2-0.3,0.3C35.3,57.2,35.2,57.3,35.2,57.5z"/>
<path fill="#C6C6C6" d="M43.6,63.6v-1.2l0.8-0.7l0.6-0.5c0.2-0.2,0.3-0.3,0.5-0.4l0.4-0.4c0.3-0.3,0.5-0.6,0.8-0.8
	c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.2-0.4,0.2-0.7c0-0.3-0.1-0.6-0.3-0.8c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
	c-0.2,0.2-0.4,0.5-0.4,0.8l-1.5-0.3c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.7,0.1,1.1,0.2
	c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.3,0.2,0.7,0.2,1c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.7-0.6,1
	c-0.3,0.4-0.6,0.7-1,1.1c-0.2,0.2-0.4,0.3-0.6,0.5l-0.6,0.5h3.1v1.4L43.6,63.6z"/>
<path fill="#C6C6C6" d="M53.1,63.8c-0.4,0-0.9-0.1-1.2-0.3c-0.4-0.2-0.7-0.5-0.9-0.8c-0.3-0.4-0.5-0.8-0.6-1.3
	c-0.1-0.6-0.2-1.1-0.2-1.7c0-0.6,0.1-1.1,0.2-1.7c0.1-0.5,0.3-0.9,0.6-1.3c0.3-0.4,0.6-0.6,1-0.8c0.4-0.2,0.8-0.3,1.3-0.3
	c0.4,0,0.9,0.1,1.2,0.3c0.4,0.2,0.7,0.5,1,0.8c0.2,0.4,0.4,0.8,0.5,1.3c0.1,0.6,0.2,1.1,0.2,1.7c0,0.6-0.1,1.2-0.2,1.7
	c-0.1,0.5-0.3,0.9-0.6,1.3c-0.2,0.3-0.6,0.6-0.9,0.8C54,63.7,53.6,63.8,53.1,63.8z M53.1,62.3c0.4,0,0.8-0.3,1-0.7
	c0.3-0.6,0.4-1.3,0.4-1.9c0-0.7-0.1-1.3-0.4-1.9c-0.3-0.6-0.9-0.8-1.5-0.5c-0.2,0.1-0.4,0.3-0.5,0.5c-0.3,0.6-0.4,1.3-0.3,1.9
	c0,0.7,0.1,1.3,0.3,1.9C52.3,62,52.7,62.3,53.1,62.3L53.1,62.3z"/>
<path fill="#C6C6C6" d="M57,63.6v-1.2l0.8-0.7l0.6-0.5c0.2-0.2,0.3-0.3,0.5-0.4l0.4-0.4c0.3-0.3,0.5-0.6,0.8-0.8
	c0.2-0.2,0.3-0.5,0.5-0.7c0.1-0.2,0.1-0.4,0.2-0.7c0-0.3-0.1-0.6-0.3-0.8C60.2,57.1,60,57,59.7,57c-0.3,0-0.5,0.1-0.8,0.3
	c-0.2,0.2-0.4,0.5-0.4,0.8L57,57.8c0.1-0.4,0.3-0.8,0.5-1.2c0.3-0.3,0.6-0.6,1-0.8c0.4-0.2,0.8-0.3,1.3-0.3c0.4,0,0.7,0.1,1.1,0.2
	c0.3,0.1,0.6,0.3,0.8,0.5c0.2,0.2,0.4,0.5,0.6,0.8c0.1,0.3,0.2,0.7,0.2,1c0,0.3-0.1,0.7-0.2,1c-0.1,0.3-0.3,0.7-0.6,1
	c-0.3,0.4-0.6,0.7-1,1.1c-0.2,0.2-0.4,0.3-0.6,0.5l-0.6,0.5h3.1v1.4L57,63.6z"/>
<path fill="#C6C6C6" d="M66.5,63.8c-0.4,0-0.9-0.1-1.3-0.3c-0.4-0.2-0.7-0.5-1-0.8c-0.3-0.4-0.5-0.8-0.6-1.3
	c-0.1-0.6-0.2-1.1-0.2-1.7c0-0.6,0.1-1.1,0.3-1.7c0.1-0.5,0.3-0.9,0.6-1.3c0.3-0.4,0.6-0.6,1-0.8c0.4-0.2,0.8-0.3,1.3-0.3
	c0.9,0,1.7,0.4,2.2,1.1c0.3,0.4,0.5,0.8,0.6,1.3c0.1,0.6,0.2,1.1,0.2,1.7c0,0.6-0.1,1.2-0.2,1.7c-0.1,0.5-0.3,0.9-0.6,1.3
	c-0.3,0.3-0.6,0.6-1,0.8C67.4,63.7,67,63.8,66.5,63.8z M66.5,62.3c0.4,0,0.8-0.3,1-0.7c0.3-0.6,0.4-1.3,0.4-1.9
	c0-0.7-0.1-1.3-0.4-1.9c-0.2-0.4-0.6-0.7-1-0.7c-0.4,0-0.8,0.3-1,0.7c-0.3,0.6-0.4,1.3-0.4,1.9c0,0.7,0.1,1.3,0.4,1.9
	C65.7,62,66.1,62.3,66.5,62.3L66.5,62.3z"/>
<path fill="#FFFFFF" d="M127.7,28.2v-8.1l-2.1,1.2l-0.7-1.8l3.1-1.7h1.8v10.4H127.7z"/>
<path fill="#FFFFFF" d="M135.8,28.4c-0.6,0-1.1-0.1-1.6-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.4-0.7-0.8-0.8-1.3
	c-0.2-0.5-0.3-1.1-0.3-1.7c0-0.6,0.1-1.1,0.3-1.6c0.2-0.5,0.5-0.9,0.8-1.3c0.4-0.4,0.8-0.7,1.2-0.9c0.5-0.2,1-0.3,1.6-0.3
	c0.5,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.9c0.4,0.4,0.6,0.8,0.8,1.3c0.2,0.5,0.3,1.1,0.3,1.7v0.5h-5.7c0.1,0.5,0.3,1,0.6,1.4
	c0.3,0.3,0.8,0.5,1.3,0.5c0.3,0,0.7-0.1,1-0.2c0.3-0.2,0.6-0.4,0.7-0.7l1.9,0.6c-0.2,0.4-0.5,0.8-0.8,1.2c-0.4,0.3-0.8,0.6-1.3,0.7
	C136.9,28.3,136.4,28.4,135.8,28.4z M135.7,21.6c-0.4,0-0.8,0.2-1.2,0.5c-0.3,0.3-0.6,0.8-0.6,1.2h3.5c-0.1-0.5-0.3-0.9-0.6-1.2
	C136.6,21.8,136.2,21.6,135.7,21.6z"/>
  </svg>
);



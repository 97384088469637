import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Link } from 'gatsby';
import ElementLink from '../components/element-link';

export default (url, type = null) => {
  if (type === 'button' || type === 'submit') {
    return {
      as: 'button',
      type,
    };
  }
  if (url?.startsWith('http') || url?.startsWith('mailto:') || url?.startsWith('tel:')) {
    return {
      as: OutboundLink,
      href: url,
      rel: 'external',
    };
  }
  if (url?.startsWith('/')) {
    return {
      as: Link,
      to: url,
    };
  }
  if (url?.startsWith('#')) {
    return {
      as: ElementLink,
      href: url,
    };
  }
  return {
    as: Link,
    to: '/404',
  };
};

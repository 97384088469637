import React from 'react';
import styled from 'styled-components';
import shortid from 'shortid';

const Logo = ({ className, withGradient }) => {
  const id = shortid.generate();
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      role="img"
      viewBox="0 0 398.1 90.12"
    >
      {withGradient && (
        <defs>
          <linearGradient id={id} y1="45.06" x2="107.6" y2="45.06" gradientUnits="userSpaceOnUse">
            <stop offset="0" stopColor="#e92160" />
            <stop offset="1" stopColor="#fb7c11" />
          </linearGradient>
        </defs>
      )}
      <path fill={withGradient ? `url(#${id})` : 'currentColor'} d="M106.8.22a1.4,1.4,0,0,0-1.5,0L54.2,27.33,2.2.23A2,2,0,0,0,.7.23,1.71,1.71,0,0,0,0,1.53v59.9a1.36,1.36,0,0,0,.8,1.3L53,89.93a2,2,0,0,0,.7.19h.2a2,2,0,0,0,.7-.19l52.2-27.2a1.56,1.56,0,0,0,.8-1.3V1.53A1.87,1.87,0,0,0,106.8.22Zm-2.3,58.51L57.6,28.93,104.6,4l-.1,54.7ZM50.7,28.93,3.1,58.83V4ZM4.6,61.43,52.2,31.52v54.8ZM56.1,31.52l47,29.91L55.5,86.33Z" />
      <g fill="currentColor">
        <path d="M173.4,28.83H183v42.7h-9.6V53.43H157.3V71.52h-9.6V28.83h9.6V45.12h16.1Z" />
        <path d="M210.7,28.83h9.6v42.7h-9.6Z" />
        <path d="M260.8,28.33c6.4,0,11.2,1.1,14.6,3.4s5.1,5.9,5.1,11-1.7,8.8-5.2,11.1-8.4,3.4-14.8,3.4h-3v14.3h-9.6v-42a65.7,65.7,0,0,1,6.6-.9Q258.25,28.33,260.8,28.33Zm.6,8.19a14.77,14.77,0,0,0-2.1.1c-.7,0-1.3.1-1.8.1V49h3a16.38,16.38,0,0,0,7.5-1.4c1.7-.9,2.5-2.6,2.5-5a6.74,6.74,0,0,0-.6-3,4.69,4.69,0,0,0-1.8-1.9,8.28,8.28,0,0,0-2.9-1c-1.1-.09-2.4-.2-3.8-.2Z" />
        <path d="M305.5,71.52V28.83h28.8v8.1H315.1v8.4h17.1v7.9H315.1v10.3h20.6v8.1H305.5Z" />
        <path d="M386.4,71.52q-1.8-3.6-3.9-7.19a28,28,0,0,0-4.9-6.2c-.6.6-1.3,1.5-2.2,2.6a33.2,33.2,0,0,0-2.6,3.6c-.9,1.3-1.7,2.6-2.5,3.9s-1.3,2.4-1.7,3.2h-11A99.24,99.24,0,0,1,364,61.12c2.4-3.5,5.2-7.3,8.2-11.3l-13.9-21h11.6l8.1,12.4,8.3-12.4h11l-13.8,20.6a139.59,139.59,0,0,1,9,12.5c2.3,3.8,4.2,7,5.6,9.6Z" />
      </g>
    </svg>
  );
};

export default styled(Logo)``;

import React from 'react';

export default ({ className }) => (
   
    <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 180 66"
  >
<path fill="#C6C6C6" d="M44.9,8c-1.4,0-2.3,1.1-2.3,4.2c0,3,0.7,4.3,2.3,4.3c1.7,0,2.2-1.9,2.2-4.3C47.1,10,46.9,8,44.9,8
			 M53.4,18.5l-6,0.1c-0.2,0-0.3-0.1-0.3-0.3v-1.7h-0.1c-0.6,1.6-2.3,2.5-3.9,2.3c-3,0-5.1-2.5-5-6.6s2.5-6.6,5.2-6.6
			c2.1,0,3.2,0.7,3.8,2.2h0.1V3.7c0-0.3,0-0.5-0.1-0.8c-0.1-0.4-0.3-0.4-0.7-0.5h-1.4c-0.2,0-0.3,0-0.2-0.3V0.5
			c0-0.2,0.1-0.3,0.2-0.3h6c0.2,0,0.3,0.1,0.3,0.3v14.2c0,0.3,0,0.5,0,0.8c0.1,0.4,0.3,0.4,0.7,0.5l1.4,0.1c0.2,0,0.3,0,0.2,0.3v2
			C53.8,18.4,53.7,18.5,53.4,18.5"/>
		<path fill="#C6C6C6" d="M39.1,5.8c-1,0.1-1.9-0.7-1.9-1.7c0,0,0-0.1,0-0.1c0-0.8,0.5-1.5,1.3-1.8l0,0c-0.3-0.3-0.7-0.4-1.1-0.4
			C36,1.8,35,2.4,35,4.5V6h2.4c0.2,0,0.2,0.1,0.2,0.3V8c0,0.2-0.1,0.3-0.2,0.3H35v6.2c0,0.3,0,0.5,0.1,0.8c0.1,0.4,0.3,0.4,0.7,0.5
			h1.3c0.2,0,0.3,0.1,0.3,0.3v2c0,0.2-0.1,0.3-0.2,0.3h-8.2c-0.2,0-0.2-0.1-0.2-0.3v-2c0-0.2,0.1-0.3,0.3-0.3s0.9,0,1.2,0
			s0.6-0.1,0.7-0.5c0.1-0.3,0.1-0.5,0.1-0.8V8.3h-2c-0.2,0-0.2-0.1-0.2-0.3V6.3c0-0.2,0.1-0.3,0.2-0.3h2V5c0-2.6,2.1-5.1,5.8-5
			c3.2,0,4.6,2.1,4.6,3.4C41.1,5,40.1,5.8,39.1,5.8"/>
		<path fill="#C6C6C6" d="M58.3,17c0-1-0.8-1.9-1.8-1.9c-1,0-1.9,0.8-1.9,1.8c0,1,0.8,1.9,1.8,1.9c1,0,1.8-0.7,1.9-1.7
			C58.3,17.1,58.3,17,58.3,17"/>
		<path fill="#C6C6C6" d="M23.1,18.2c0-2.2-1.6-2.5-1.6-2.5c-1.2-0.1-2.5-0.3-3.7-0.7c-1.1-0.6-2.4-1-3.7-1
			c-2.7-1.6-5.6-2.8-8.7-3.6c0,0-1.9-0.5-1.1,0.3c0.4,0.4,5.9,2,7.6,4l-0.2,0.1C9.3,13.4,3,11.6,1.2,11.3c0,0-2.3,0-0.4,0.7
			c1.3,0.5,7.5,2.8,8.6,4.1s-1.6,2.4-2.9,2.1c0,0-0.9,0,0.3,1.1c1.2,1.1,4,0.5,4,0.5s0,0.1,0,0.4c0.6,2.4,1.8,4.6,3.5,6.5
			c0,0-11.4,1.8-12.4,13c-0.6,7.5,1.9,15.4,8.5,15.9c5,0.4,6.7-3,7-5.7c0.4-3.3-1.9-6.3-5.2-6.7c-0.1,0-0.2,0-0.4,0
			c-4.4-0.4-5.1,2.4-5.1,2.4S4.6,33.5,19.3,35.2l-0.4,5.4c1.9,0.3,3.8-0.2,5.2-1.5l0.3-12.7c0,0-0.5-1.5-3.8-2.8
			c-2.5-1-3.1-3.9-0.9-4.2c0.6-0.2,1.3-0.3,2-0.3C21.7,19.1,23.1,19.2,23.1,18.2"/>
		<path fill="#C6C6C6" d="M28.9,23.8h32.4h27.6h23.8c3.9,0,7.8,0,11.6,0h0.2c1.2,0,1.4-1.9,0.1-1.9h-9.5H92.3H64.6H40.9
			c-3.9,0-7.8,0-11.6,0h-0.2C27.9,21.9,27.7,23.8,28.9,23.8"/>
		<path fill="#C6C6C6" d="M29.4,38.2v-6.4c-0.1-1.4,0.4-2.8,1.4-3.7c1-0.8,2.3-1.2,3.5-1.2c1.3-0.1,2.6,0.3,3.6,1.1
			c0.9,0.7,1.4,1.8,1.4,3c0,0.3-0.1,0.6-0.3,0.9c-0.3,0.2-0.6,0.3-0.9,0.2c-0.8,0-1.2-0.3-1.2-0.8c0-1.3-1-2.4-2.3-2.4
			c-0.1,0-0.2,0-0.3,0c-1.7,0-2.6,1-2.6,2.9v6.4c0,1.9,0.9,2.9,2.6,2.9c0.7,0.1,1.5-0.2,2-0.7c0.5-0.6,0.7-1.4,0.7-2.2v-1.8h-2.3
			c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2-0.2-0.4-0.2-0.7c0-0.2,0.1-0.5,0.2-0.6c0.1-0.2,0.3-0.3,0.5-0.3h3.8c0.2,0,0.4,0.1,0.6,0.2
			c0.1,0.2,0.2,0.4,0.2,0.6v2.9c0.1,1.4-0.4,2.8-1.4,3.7c-2.2,1.6-5.1,1.6-7.2,0C29.8,41,29.3,39.6,29.4,38.2"/>
		<path fill="#C6C6C6" d="M44.2,37.5h4.4l-2.2-7.6L44.2,37.5z M40.4,42c0-0.1,0-0.1,0-0.2l4.4-14.4c0.2-0.5,0.7-0.8,1.6-0.8
			s1.4,0.2,1.6,0.8l4.4,14.4c0,0.1,0,0.1,0,0.2c0,0.3-0.2,0.6-0.5,0.7c-0.3,0.2-0.7,0.3-1.1,0.3c-0.5,0-0.8-0.2-0.9-0.5L49,39.4
			h-5.4l-0.9,3.2c-0.1,0.3-0.4,0.5-0.8,0.5c-0.4,0-0.7-0.1-1-0.3C40.6,42.6,40.4,42.3,40.4,42"/>
		<path fill="#C6C6C6" d="M52.6,42.3c0-0.3,0.1-0.6,0.2-0.8l6.9-12.6h-6c-0.2,0-0.4-0.1-0.6-0.3c-0.1-0.2-0.2-0.5-0.2-0.7
			c0-0.3,0.1-0.5,0.2-0.7c0.1-0.2,0.3-0.3,0.6-0.3H62c0.5,0,0.8,0.2,0.8,0.7c0,0.3-0.1,0.6-0.3,0.9l-6.9,12.6h6.7
			c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.3h-9C52.9,43,52.6,42.8,52.6,42.3"
			/>
		<path fill="#C6C6C6" d="M64.6,42.3V27.6c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.5-0.2,0.7-0.2h7.9c0.2,0,0.5,0.1,0.6,0.3
			c0.3,0.5,0.3,1,0,1.5c-0.1,0.2-0.3,0.3-0.6,0.3H67V34h3.4c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.2,0.6
			c-0.1,0.2-0.4,0.3-0.6,0.3H67V41h6.6c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.7
			c-0.1,0.2-0.3,0.3-0.6,0.3h-7.9c-0.3,0-0.5-0.1-0.7-0.2C64.7,42.7,64.6,42.5,64.6,42.3"/>
		<path fill="#C6C6C6" d="M75.9,42.3V27.6c0-0.2,0.1-0.5,0.3-0.6c0.3-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2
			c0.2,0.1,0.4,0.3,0.3,0.6V41H84c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.5,0.3
			h-7c-0.3,0-0.5-0.1-0.7-0.2C76,42.8,75.9,42.5,75.9,42.3"/>
		<path fill="#C6C6C6" d="M86.1,42.3V27.6c0-0.2,0.1-0.5,0.3-0.6c0.3-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2
			c0.2,0.1,0.3,0.3,0.3,0.6l0,13.4h5.8c0.2,0,0.4,0.1,0.5,0.3c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.7
			c-0.1,0.2-0.3,0.3-0.5,0.3h-7.1c-0.3,0-0.5-0.1-0.7-0.2C86.2,42.7,86.1,42.5,86.1,42.3"/>
		<path fill="#C6C6C6" d="M96.4,42.3V27.7c0-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.5-0.2,0.7-0.2h7.9c0.2,0,0.5,0.1,0.6,0.3
			c0.1,0.2,0.2,0.5,0.2,0.7c0,0.3-0.1,0.5-0.2,0.8c-0.1,0.2-0.3,0.3-0.6,0.3h-6.6v5h3.4c0.2,0,0.4,0.1,0.6,0.3
			c0.1,0.2,0.2,0.4,0.2,0.7c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.4,0.3-0.6,0.3h-3.4V41h6.6c0.2,0,0.4,0.1,0.6,0.3
			c0.1,0.2,0.2,0.5,0.2,0.8c0,0.2-0.1,0.5-0.2,0.7c-0.1,0.2-0.3,0.3-0.6,0.3h-7.9c-0.3,0-0.5-0.1-0.7-0.2
			C96.5,42.8,96.4,42.5,96.4,42.3"/>
		<path fill="#C6C6C6" d="M107.6,42.3V27.7c0-0.5,0.4-0.8,1.2-0.8c0.4,0,0.7,0.1,1,0.3c0.3,0.3,0.6,0.6,0.8,1l4.9,9.6V27.7
			c0-0.2,0.1-0.5,0.4-0.6c0.3-0.1,0.5-0.2,0.8-0.2c0.3,0,0.6,0.1,0.8,0.2c0.2,0.1,0.3,0.3,0.3,0.6v14.7c0,0.2-0.1,0.5-0.3,0.6
			c-0.3,0.1-0.5,0.2-0.8,0.2c-0.6,0-1.1-0.3-1.4-0.9L110,32.2v10.2c0,0.2-0.1,0.5-0.4,0.6c-0.3,0.1-0.5,0.2-0.8,0.2
			c-0.3,0-0.6-0.1-0.8-0.2C107.8,42.8,107.6,42.6,107.6,42.3"/>
		<path fill="#C6C6C6" d="M25.1,47.9h87.7c4.1,0,8.1,0,12.1,0h0.2c1.2,0,1.4-1.9,0.2-1.9H37.6c-4,0-8.1,0-12.1,0h-0.2
			C24,46,23.9,47.9,25.1,47.9"/>
<path fill="#C6C6C6" d="M28.4,63.8V62c0-0.6,0.2-1.2,0.6-1.6c0.4-0.6,0.9-1.1,1.5-1.5l1.8-1.3c0.6-0.4,1.1-0.9,1.5-1.4
	c0.4-0.4,0.7-1,0.7-1.6c0-0.5-0.2-1-0.5-1.4c-0.4-0.4-0.9-0.6-1.5-0.6c-0.5,0-1,0.1-1.4,0.5c-0.4,0.4-0.6,0.9-0.5,1.4
	c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.2-0.5,0.2-0.8,0.2c-0.6,0-0.9-0.4-0.9-1.1c0-0.9,0.4-1.8,1.1-2.4c1.6-1.3,3.9-1.2,5.5,0.1
	c0.8,0.7,1.2,1.7,1.2,2.7c0,0.7-0.2,1.5-0.6,2.1c-0.4,0.6-0.9,1.2-1.5,1.7c-0.6,0.5-1.2,0.9-1.8,1.3c-0.5,0.4-1,0.8-1.5,1.2
	c-0.4,0.3-0.6,0.7-0.7,1.2v0.7h5.5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.5,0.3
	h-6.6c-0.2,0-0.4-0.1-0.6-0.2C28.5,64.2,28.3,64,28.4,63.8z"/>
<path fill="#C6C6C6" d="M38,60.4V55c-0.1-1.2,0.3-2.3,1.1-3.1c1.8-1.3,4.3-1.3,6.1,0c0.8,0.8,1.3,2,1.2,3.1v5.4
	c0.1,1.2-0.3,2.3-1.1,3.1c-1.8,1.3-4.3,1.3-6.1,0C38.3,62.7,37.9,61.6,38,60.4z M40,60.4c0,1.6,0.7,2.4,2.2,2.4
	c0.6,0,1.2-0.2,1.7-0.6c0.4-0.5,0.6-1.1,0.6-1.8V55c0.1-0.7-0.2-1.3-0.6-1.8c-0.4-0.4-1-0.6-1.7-0.6c-1.5,0-2.2,0.8-2.2,2.4V60.4z
	 M41.7,58.2c-0.2-0.1-0.2-0.4-0.2-0.6c0-0.2,0.1-0.4,0.2-0.6c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
	c0.2,0.1,0.2,0.3,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.2,0.1-0.4,0.2-0.6,0.2C42,58.5,41.8,58.4,41.7,58.2L41.7,58.2z"/>
<path fill="#C6C6C6" d="M47.5,53.6c0-0.3,0.1-0.6,0.4-0.8l1.9-1.9c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.6,0.2
	c0.2,0.1,0.3,0.3,0.3,0.5v12.3c0,0.2-0.1,0.4-0.3,0.5c-0.4,0.2-1,0.2-1.4,0c-0.2-0.1-0.3-0.3-0.3-0.5V53.4l-0.7,0.8
	c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.4-0.1-0.5-0.2C47.6,54,47.5,53.8,47.5,53.6z"/>
<path fill="#C6C6C6" d="M53.1,61.2c0-0.2,0.1-0.5,0.2-0.7c0.2-0.1,0.5-0.2,0.7-0.2c0.7,0,1,0.2,1,0.7c0,0.5,0.2,0.9,0.6,1.2
	c0.5,0.3,1,0.5,1.6,0.5c1.5,0,2.2-0.8,2.2-2.4V58c-0.7,0.7-1.6,1.1-2.6,1c-2.5,0-3.8-1.3-3.8-3.8V55c-0.1-1.2,0.3-2.3,1.1-3.1
	c1.8-1.3,4.3-1.3,6.1,0c0.8,0.8,1.2,2,1.1,3.1v5.4c0.1,1.2-0.3,2.3-1.2,3.1c-0.9,0.7-2,1.1-3.1,1c-1.1,0.1-2.2-0.3-3-1
	C53.5,63,53.1,62.1,53.1,61.2z M55.1,55.2c0,1.5,0.7,2.3,2.2,2.3c1,0.2,2-0.6,2.2-1.6c0-0.2,0-0.4,0-0.5v-0.5c0-1.6-0.7-2.4-2.2-2.4
	s-2.2,0.8-2.2,2.4V55.2z"/>
<circle fill="#69647B" cx="122.2" cy="22.4" r="12.6"/>
<path fill="#FFFFFF" d="M114.1,27.9v-1.6l1.1-0.9l0.8-0.7c0.2-0.2,0.4-0.4,0.6-0.6l0.6-0.5c0.4-0.4,0.8-0.8,1.1-1.2
	c0.3-0.3,0.5-0.6,0.6-1c0.1-0.3,0.2-0.6,0.2-0.9c0-0.4-0.1-0.8-0.4-1.1c-0.3-0.3-0.6-0.4-1-0.4c-0.4,0-0.7,0.1-1,0.4
	c-0.3,0.3-0.5,0.6-0.6,1L114,20c0.1-0.6,0.4-1.1,0.7-1.6c0.3-0.4,0.8-0.8,1.3-1c1-0.5,2.1-0.5,3.2-0.1c0.4,0.2,0.8,0.4,1.1,0.7
	c0.3,0.3,0.6,0.7,0.8,1.1c0.2,0.4,0.3,0.9,0.3,1.4c0,0.4-0.1,0.9-0.2,1.3c-0.2,0.5-0.5,0.9-0.8,1.3c-0.4,0.5-0.9,1-1.4,1.5l-0.8,0.7
	l-0.8,0.7h4.1v1.9L114.1,27.9z"/>
<path fill="#FFFFFF" d="M126.9,28.2c-0.6,0-1.1-0.1-1.7-0.3c-0.5-0.2-0.9-0.5-1.3-0.9c-0.4-0.4-0.7-0.9-0.9-1.4
	c-0.2-0.6-0.3-1.1-0.3-1.7c0-0.6,0.1-1.2,0.3-1.7c0.2-0.5,0.5-1,0.9-1.4c0.4-0.4,0.8-0.7,1.3-0.9c0.5-0.2,1-0.3,1.6-0.3
	c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.6,0.9,0.8,1.4c0.2,0.6,0.3,1.2,0.3,1.8v0.5h-5.8c0.1,0.5,0.3,1,0.7,1.4
	c0.4,0.3,0.8,0.5,1.3,0.5c0.4,0,0.7-0.1,1-0.3c0.3-0.2,0.6-0.4,0.7-0.8l1.9,0.6c-0.2,0.5-0.5,0.9-0.8,1.2c-0.4,0.3-0.8,0.6-1.3,0.8
	C127.9,28.1,127.4,28.2,126.9,28.2z M126.8,21.2c-0.4,0-0.9,0.2-1.2,0.5c-0.3,0.3-0.6,0.8-0.6,1.3h3.6c-0.1-0.5-0.3-0.9-0.6-1.3
	C127.6,21.4,127.2,21.2,126.8,21.2z"/>
<path fill="#C6C6C6" d="M81.7,63.6v-1.8c0-0.6,0.2-1.2,0.6-1.6c0.4-0.5,0.9-1,1.5-1.4l1.8-1.3c0.6-0.4,1.1-0.9,1.5-1.4
	c0.4-0.4,0.6-1,0.6-1.5c0-0.5-0.2-1-0.5-1.3c-0.4-0.4-0.9-0.6-1.5-0.6c-0.5,0-1,0.1-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3
	c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.2-0.5,0.2-0.7,0.2c-0.6,0-0.9-0.4-0.9-1.1c0-0.9,0.4-1.8,1.1-2.3c1.6-1.2,3.9-1.2,5.4,0.1
	c0.8,0.7,1.2,1.6,1.1,2.6c0,0.7-0.2,1.4-0.6,2c-0.4,0.6-0.9,1.2-1.5,1.7c-0.6,0.4-1.2,0.9-1.7,1.3c-0.5,0.3-1,0.7-1.5,1.2
	c-0.4,0.3-0.6,0.7-0.6,1.2v0.7H89c0.2,0,0.3,0.1,0.4,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.3
	h-6.4c-0.2,0-0.4-0.1-0.6-0.2C81.8,64,81.7,63.8,81.7,63.6z"/>
<path fill="#C6C6C6" d="M91.2,60.3V55c-0.1-1.1,0.3-2.3,1.1-3.1c1.8-1.3,4.2-1.3,6,0c0.8,0.8,1.2,1.9,1.1,3.1v5.3
	c0.1,1.1-0.3,2.3-1.1,3.1c-0.8,0.7-1.9,1-3,1c-1.1,0.1-2.2-0.3-3-1C91.5,62.6,91.1,61.5,91.2,60.3z M93.1,60.3
	c0,1.6,0.7,2.4,2.2,2.4c0.6,0,1.2-0.2,1.6-0.6c0.4-0.5,0.6-1.1,0.6-1.8V55c0.1-0.6-0.1-1.3-0.6-1.8c-0.4-0.4-1-0.6-1.6-0.6
	c-1.5,0-2.2,0.8-2.2,2.4V60.3z M94.7,58.2c-0.3-0.3-0.3-0.8,0-1.1c0,0,0,0,0,0c0.2-0.1,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2
	c0.3,0.3,0.3,0.8,0,1.1C95.6,58.5,95.1,58.5,94.7,58.2z"/>
<path fill="#C6C6C6" d="M101.4,63.6v-1.8c0-0.6,0.2-1.2,0.6-1.6c0.4-0.5,0.9-1,1.5-1.4l1.8-1.3c0.6-0.4,1.1-0.9,1.5-1.4
	c0.4-0.4,0.6-1,0.6-1.5c0-0.5-0.2-1-0.5-1.3c-0.4-0.4-0.9-0.6-1.4-0.6c-0.5,0-1,0.1-1.3,0.5c-0.4,0.4-0.5,0.8-0.5,1.3
	c0,0.2-0.1,0.4-0.3,0.5c-0.2,0.2-0.5,0.2-0.7,0.2c-0.6,0-0.9-0.4-0.9-1.1c0-0.9,0.4-1.8,1.1-2.3c1.6-1.2,3.9-1.2,5.4,0.1
	c0.8,0.7,1.2,1.6,1.1,2.6c0,0.7-0.2,1.4-0.6,2c-0.4,0.6-0.9,1.2-1.5,1.6c-0.6,0.4-1.1,0.9-1.7,1.3c-0.5,0.3-1,0.7-1.5,1.2
	c-0.4,0.3-0.6,0.7-0.6,1.2v0.7h5.4c0.2,0,0.4,0.1,0.4,0.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2-0.1,0.4-0.2,0.6c-0.1,0.2-0.3,0.3-0.4,0.3
	h-6.5c-0.2,0-0.4-0.1-0.6-0.2C101.6,64,101.5,63.8,101.4,63.6z"/>
<path fill="#C6C6C6" d="M111,60.3V55c-0.1-1.1,0.3-2.3,1.1-3.1c1.8-1.3,4.2-1.3,6,0c0.8,0.8,1.2,1.9,1.1,3.1v5.3
	c0.1,1.1-0.3,2.3-1.1,3.1c-0.8,0.7-1.9,1-3,1c-1.1,0.1-2.2-0.3-3-1C111.3,62.6,110.9,61.5,111,60.3z M112.9,60.3
	c0,1.6,0.7,2.4,2.2,2.4c0.6,0,1.2-0.2,1.6-0.6c0.4-0.5,0.6-1.1,0.6-1.8V55c0.1-0.6-0.2-1.3-0.6-1.8c-0.4-0.4-1-0.6-1.6-0.6
	c-1.5,0-2.2,0.8-2.2,2.4V60.3z M114.5,58.2c-0.2-0.1-0.2-0.3-0.2-0.6c0-0.2,0.1-0.4,0.2-0.5c0.2-0.1,0.4-0.2,0.6-0.2
	c0.2,0,0.4,0.1,0.6,0.2c0.3,0.3,0.3,0.8,0,1.1c-0.2,0.2-0.4,0.2-0.6,0.2C114.9,58.4,114.7,58.3,114.5,58.2z"/>
<path fill="#C6C6C6" d="M70.3,64.3c-0.6,0-1.2-0.1-1.8-0.3c-0.5-0.2-1-0.4-1.4-0.8c-0.4-0.3-0.7-0.7-0.9-1.2c-0.2-0.5-0.3-1-0.3-1.5
	c0-0.7,0.2-1.4,0.6-2c0.4-0.6,1-1.2,1.7-1.5c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.3-0.3-0.6-0.4-1c-0.1-0.3-0.1-0.7-0.1-1
	c0-0.5,0.1-0.9,0.3-1.3c0.2-0.4,0.4-0.8,0.7-1c0.3-0.3,0.7-0.5,1.1-0.7c0.9-0.3,1.8-0.3,2.7,0c0.4,0.1,0.8,0.4,1.1,0.6
	c0.3,0.3,0.5,0.6,0.7,1c0.2,0.4,0.3,0.8,0.2,1.2c0,1.3-0.8,2.5-2.5,3.4l2.8,2.8c0.3-0.5,0.5-1,0.6-1.5c0.2-0.6,0.3-1.2,0.3-1.8
	l1.8,0.2c-0.1,0.8-0.3,1.6-0.5,2.4c-0.2,0.7-0.5,1.4-0.9,2l2.4,2.4h-2.8l-1-1c-0.5,0.4-1.1,0.8-1.7,1C71.7,64.2,71,64.3,70.3,64.3z
	 M68.1,60.4c0,0.4,0.1,0.7,0.3,1.1c0.2,0.3,0.5,0.6,0.8,0.7c0.4,0.2,0.8,0.3,1.3,0.3c0.4,0,0.8-0.1,1.2-0.2c0.4-0.1,0.7-0.3,1.1-0.6
	l-3.4-3.4c-0.4,0.2-0.8,0.5-1,0.9C68.2,59.6,68.1,60,68.1,60.4z M68.9,54.1c0,0.4,0.1,0.7,0.3,1c0.3,0.4,0.6,0.8,0.9,1.1
	c0.5-0.2,0.9-0.6,1.3-1c0.3-0.3,0.4-0.8,0.4-1.2c0-0.4-0.1-0.7-0.4-1c-0.3-0.2-0.6-0.4-1-0.4c-0.3,0-0.5,0.1-0.8,0.2
	c-0.2,0.1-0.4,0.3-0.5,0.5C69,53.6,68.9,53.8,68.9,54.1z"/>
  </svg>
);
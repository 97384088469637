import React from 'react';
import styled, { css } from 'styled-components';
import DropdownList from './DropdownList';

export default function DropdownSubmenu({ isOpen, title, items }) {
  let localItems = [];
  if (items && items.length > 0) {
    switch (items.length) {
      case 1:
        localItems = [
          null,
          ...items,
          null,
        ];
        break;
      case 2:
        localItems = [
          ...items,
          null,
        ];
        break;
      case 3:
      default:
        localItems = [
          ...items,
        ];
        break;
    }
  }

  if (!isOpen || !items || items.length === 0) {
    return null;
  }

  if (items.length === 1) {
    const child = items[0];
    return (
      <DropdownList
        hasIcon={Boolean((child.items || []).find((item) => item.icon))}
        list={localItems}
        child={child}
        items={items}
      />
    );
  }

  return (
    <Dropdown isOpen={isOpen} key={`${title}-dropdown-menu`}>
      <DropdownContainer style={items && items.length < 2 ? { paddingTop: 0 } : {}}>
        {localItems && localItems.length > 0 && localItems.map((child) => {
          if (child === null) {
            return <DropdownList />;
          }
          return (
            <DropdownList
              hasIcon={Boolean((child.items || []).find((item) => item.icon))}
              list={localItems}
              child={child}
              items={items}
            />
          );
        })}
      </DropdownContainer>
    </Dropdown>
  );
}

const Dropdown = styled.div`
  display: flex;
  width: 100%;
  left: 0;

  @media all and (max-width: ${(props) => props.theme.breakingpoints.md}) {
    padding-top: .5rem;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px;
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    margin: 0;
    background-color: ${(props) => props.theme.white};
    position: absolute;
    visibility: visible;
    opacity: 1;
    transition: opacity 0.2s;
    ${(props) => !props.isOpen && css`
      display: block;
      opacity: 0;
      visibility: hidden;
    `}

    &::before {
      border-top: 2px solid rgba(0, 0, 0, 0.2);
      content: '';
      display: block;
      // position: absolute;
      background-color: inherit;
      // height: 100%;
      // width: 300vw;
      // margin-left: -100vw;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    }
  }
`;

const DropdownContainer = styled.div`
  padding-left: ${(props) => props.theme.grid.containerGap};
  padding-right: ${(props) => props.theme.grid.containerGap};
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
  }
  
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    max-width: ${(props) => props.theme.breakingpoints.sm};
    flex-direction: row;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    max-width: ${(props) => props.theme.breakingpoints.md};
    width: 100%;
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    max-width: ${(props) => props.theme.breakingpoints.lg};
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    max-width: ${(props) => (props.small ? props.theme.breakingpoints.lg : props.theme.breakingpoints.xl)}
  }
`;

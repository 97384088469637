import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import Icon from '../icons';
import getButtonElement from '../../helpers/get-button-element';
import DropdownSubmenu from './nav/DropdownSubmenu';

export const activeClassName = 'active';

const StyledIcon = styled(Icon)`
  transition: transform 0.1s;
  ${(props) => props.isOpen && css`
    transform: rotate(180deg);
  `}
`;

const Button = styled.button`
  border: unset;
  padding: unset;
  background: unset;
  fill: ${(props) => props.theme.fontColor};
  display: block;
  width: .6rem;
  margin-left: 0.2rem;
  line-height: 0;
  cursor: pointer;
  svg {
    vertical-align: middle;
  }
`;

const TopLevel = styled.span`
  display: flex;
  align-items: center;
  
  @media all and (max-width: ${(props) => props.theme.breakingpoints.md}) {
    &.${activeClassName} {
      background-color: ${(props) => props.theme.gray1};
      border-radius: 50px;
      padding: .5rem 1rem .5rem 1rem;
      margin: .5rem 0rem .5rem 0rem;
    }
  }
  
  > * {
    flex-grow: 1;
  }
  ${Button} {
    flex-grow: 0;
  }
  padding: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    &:hover {
      color: ${(props) => props.theme.primaryColor};
      ${Button} {
        fill: ${(props) => props.theme.primaryColor};
      }
    }
  }
`;

const StyledLink = styled(Link)`
  display: block;
  color: inherit;
  font-size: .9rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  text-decoration: none;
  cursor: pointer;
  &.${activeClassName} {
    color: ${(props) => props.theme.primaryColor};
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    padding: 0.5rem 0;
  }
`;

const NavItem = ({
  className,
  slug,
  href,
  title,
  children,
  disabled,
}) => {
  const [isOpen, setOpen] = useState(false);
  // use a ref, because we need to mutate it realtime
  // useState would have old state saved inside the function
  const mouseEnteredRef = useRef();

  const handleOpen = () => {
    // container to know that we entered the nav item
    mouseEnteredRef.current = true;
    setOpen(true);
  };

  const handleClose = () => {
    // container to know that we left the nav item
    mouseEnteredRef.current = false;
    const doClose = () => {
      // when we still left the nav item after the timeout, close the nav
      if (!mouseEnteredRef.current) {
        setOpen(false);
      }
    };
    setTimeout(doClose, 250);
  };

  const toggleClose = () => {
    setOpen(!isOpen);
  };

  const link = slug || href;
  const props = getButtonElement(link);
  const { to } = props;

  return (
    <li
      className={className}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
    >
      <TopLevel className={isOpen ? activeClassName : ''}>
        <StyledLink
          {...{
            ...props,
            // we are only allowed to pass activeClassName on Link
            // (that has a to from the props)
            ...(to && {
              activeClassName,
              partiallyActive: true,
            }),
            ...(disabled && {
              // don't render disabled links to prevent google detecting 404's
              as: 'span',
              to: undefined,
            }),
          }}
          onClick={toggleClose}
        >
          {title}
        </StyledLink>
        {children && children.length > 0 && (
          <Button onClick={toggleClose}>
            <StyledIcon icon="arrow-down" isOpen={isOpen} />
          </Button>
        )}
      </TopLevel>
      <DropdownSubmenu isOpen={isOpen} items={children} title={title} />
    </li>
  );
};

export default styled(NavItem)`
  margin: 0;
  color: ${(props) => props.theme.fontColor};
  border-top: 1px solid ${(props) => props.theme.gray2};

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    border-top: 0;
  }

  ${(props) => props.mobileOnly && css`
    @media all and (min-width: ${props.theme.breakingpoints.xl}) {
      display: none;
    }
  `}
`;

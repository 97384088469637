import React, { useCallback } from 'react';

const ElementLink = ({
  href, children, onClick, ...props
}) => {
  const handleClick = useCallback((e) => {
    if (onClick) onClick();

    const element = document.querySelector(href);
    // Only do a smooth scroll if an element with the ID from the href can
    // can be found.
    if (element) {
      const { top } = element.getBoundingClientRect();
      window.scrollTo({
        // top - +/- the header size
        top: top - 110,
        left: 0,
        behavior: 'smooth',
      });

      // Push the hash into the history, settings window.location.hash directly
      // will cause a scroll to.
      window.history.pushState(null, null, href);

      // If everything worked, prevent the default behavior to
      // prevent skipping to the content without the smooth scroll
      e.preventDefault();
    }
  }, [href, onClick]);

  return (
    <a
      {...props}
      href={href}
      onClick={handleClick}
    >
      {children}
    </a>
  );
};

export default ElementLink;

export default {
  blogBy: (by) => `Door ${by} | `,
  blogLinkTitle: 'Nieuws & informatie',
  blogCategoriesTitle: 'Populaire categorieën:',
  footerTagline: 'Hypersnelle E-commerce hosting',
  blogMetaTitle: 'Hipex blogs',
  blogMetaDescription: 'Alle blogs over het hosten van Magento Shopware en andere platformen',
  supportBlockContactEmailTitle: 'Stuur ons een e-mail',
  supportBlockContactTelTitle: 'Bel met ons',
  pricingPlanOrderNow: (pk) => `${pk} bestellen`,
  pricingplansTitle: 'Meest populaire pakketten',
  clientBlockButton: 'Lees deze case',
  heroProductPageNoTextUnderButton: 'Geen creditcard nodig | Makkelijk geregeld',
  heroHostingTitle: 'Kies je hosting',
  next: 'Volgende',
  prev: 'Vorige',
  pageTitleSupport: 'Support',
  pageTitleLogin: 'Inloggen',
  pricingPlanTryForFreeButtonUrl: '/gratis-uitproberen/',
  pricingPlanTryForFreeButtonText: 'Nu bestellen',
  ctaTryForFreeButtonUrl: '/gratis-uitproberen/',
  privacyPolicyUrl: '/privacybeleid/',
  cookiePolicyUrl: '/cookiebeleid/',
  // ctaTryForFreeButtonText: 'Gratis uitproberen',
  ctaTryForFreeButtonText: 'Gratis uitproberen',
  resultsLoader: 'Retrieving results...',
  // Performance Scan
  performanceScanBranchAverage: 'Branch gem.',
  performanceScanCompareWithCompetitors: 'Benchmark',
  bannerAnnouncement: 'Gebruik vanaf nu voor spoed issues de Hypernode Emergency Service',
  bannerAnnouncementUrl: 'https://us2.campaign-archive.com/?e=[UNIQID]&u=5534bac1e8c44f319f586aec5&id=63e0a46472',
  bannerAnnouncementUrlReadMore: 'Lees meer',
};

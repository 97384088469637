import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';
import Button from '../../components/button';

const StyledBanner = styled.div`
  background-color: 'green';
  color: white;
  background: linear-gradient(90deg,rgb(233,30,99),rgb(252,128,14));
  text-align: center;
  font-size: 0.8em;
  padding: 0.8em 0;


  a {
    color: white;
  }

`;

const Banner = () => (
  <StyledBanner>
    {i18n(
      'bannerAnnouncement',
      'Hipex will implement a price change as of January 1, 2023.',
    )}. <a target="_blank" rel="noreferrer" href={i18n('bannerAnnouncementUrl', '#')}>{i18n('bannerAnnouncementUrlReadMore')}</a>
  </StyledBanner>
);

export default Banner;

import React from 'react';
import styled, { css } from 'styled-components';
import Icon from '../../layout/icons';
import getButtonElement from '../../helpers/get-button-element';
import { push } from '../../analytics';

const Button = ({
  arrow,
  url,
  type,
  analyticsEvent,
  onClick,
  ...props
}) => {
  const {
    children,
  } = props;
  return (
    <ButtonElement
      {...props}
      {...getButtonElement(url, type)}
      onClick={analyticsEvent
        ? (e) => { push({ event: analyticsEvent }); return onClick?.(e); }
        : onClick}
    >
      {children}
      {arrow && (
        <Arrow
          aria-hidden="true"
          focusable="false"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path fill="currentColor" d="M8 256c0 137 111 248 248 248s248-111 248-248S393 8 256 8 8 119 8 256zm448 0c0 110.5-89.5 200-200 200S56 366.5 56 256 145.5 56 256 56s200 89.5 200 200zM266.9 126.1l121.4 121.4c4.7 4.7 4.7 12.3 0 17L266.9 385.9c-4.7 4.7-12.3 4.7-17 0l-19.6-19.6c-4.8-4.8-4.7-12.5.2-17.2l70.3-67.1H140c-6.6 0-12-5.4-12-12v-28c0-6.6 5.4-12 12-12h160.8l-70.3-67.1c-4.9-4.7-5-12.4-.2-17.2l19.6-19.6c4.7-4.7 12.3-4.7 17 0z" />
        </Arrow>
      )}
    </ButtonElement>
  );
};

export default styled(Button)``;

const Arrow = styled.svg`
  width: 1.2rem;
  margin-left: .5rem;
`;

// `&&` results in `.Button.Button` which is more important than `.Content a`
export const ButtonElement = styled.button`
  && {
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.8rem 3rem;
    ${(props) => props.variant && css`
      ${(props.variant === 'secondary' || props.variant === 'secondaryWithArrow') && css`
        padding-left: 0;
        padding-right: 0;
      `}
      background: ${props.theme.buttons[props.variant].backgroundColor};
      ${props.theme.buttons[props.variant].borderColor ? css`
        border: 1px solid ${props.theme.buttons[props.variant].borderColor};
      ` : css`border: 0;`}
      color: ${props.theme.buttons[props.variant].color};
    `};
    text-decoration: none;
    cursor: pointer;
    font-family: inherit;
    font-family: ${(props) => props.theme.buttonFont};
    border-radius: ${(props) => props.theme.borderRadius};
    border-radius: ${(props) => (props.variant === 'primaryGradientSquare' ? '0' : '1.5rem')};
    font-size: .8889rem;
    ${Icon} {
      width: .6rem;
      margin-left: 1rem;
    }

    &:disabled {
      opacity: .5;
    }
    &:hover {
      ${(props) => props.variant && css`
        background: ${props.theme.buttons[props.variant].hover.backgroundColor};
        border-color: ${props.theme.buttons[props.variant].hover.borderColor};
        color: ${props.theme.buttons[props.variant].hover.color};
      `};
    }
  }
`;

import translations from './translations';

export default (key, fallback, ...args) => {
  const field = translations[key];
  if (!field) {
    return fallback;
  }
  if (field.constructor === Function) {
    return translations[key](...args);
  }
  return field;
};

import { createContext, useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

const SiteMetadataContext = createContext(null);

export const useSiteMetadata = () => useContext(SiteMetadataContext);

export const useGetSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            description
            name
            email
            supportEmail
            tel
            siteUrl
            lang
            locale
            title
            newsPath
            socialLinks {
              facebook
              twitter
              linkedin
            }
            address {
              name
              street
              number
              zipcode
              city
            },
            maps {
              lat
              lng
              apiKey
            },
          }
        }
      }
    `,
  );
  return site.siteMetadata;
};

export default SiteMetadataContext;

import React from 'react';
import styled from 'styled-components';
import Icon from '../icons';
import { useSiteMetadata } from '../../context/siteMetadataContext';
import IconWrapper from '../../components/IconWrapper';

const Socials = ({ className, color, hoverColor }) => {
  const { socialLinks } = useSiteMetadata();
  return (
    <div className={className}>
      <IconWrapper href={socialLinks.facebook} footer color={color} hoverColor={hoverColor}>
        <StyledIcon icon="facebook" />
      </IconWrapper>

      <IconWrapper href={socialLinks.twitter} footer color={color} hoverColor={hoverColor}>
        <StyledIcon icon="twitter" />
      </IconWrapper>

      <IconWrapper href={socialLinks.linkedin} footer color={color} hoverColor={hoverColor}>
        <StyledIcon icon="linkedin" />
      </IconWrapper>
    </div>
  );
};

const StyledIcon = styled(Icon)`
  height: 0.5rem;
`;

export default styled(Socials)`
  display: flex;
  margin-bottom: 1rem;
  
  ${(props) => (props.sidebar ? `
    & > a {
      color: #E91E63;
      border-color: #E91E63;
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 1rem;
      margin-top: .5rem;
      
      > svg {
        height: .75rem;
      }
    }
  ` : '')}
  @media print {
    display: none;
  }
`;

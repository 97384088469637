import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Container from '../../components/container';
import i18n from '../../i18n';
import Icon from '../icons';
import { useSiteMetadata } from '../../context/siteMetadataContext';
import { push } from '../../analytics';

const Phone = styled(Icon)`
  width: 1rem;
  margin-right: 0.5rem;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Tel = styled.div`
  margin-left: 1rem;
  a {
    display: flex;
    align-items: center;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.themeColor};
    }
    color: inherit;
  }
`;

const Links = styled.div`
  margin-left: 1rem;
  padding: 0.5rem 1rem;
  background-color: ${(props) => props.theme.gray1};
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      color: ${(props) => props.theme.themeColor};
    }

    & + a {
      margin-left: 0.5rem;
      padding-left: 0.5rem;
      border-left: 1px solid ${(props) => props.theme.fontColor};
    }
  }
`;

const TopHeader = ({ className }) => {
  const { tel } = useSiteMetadata();

  return (
    <div className={className}>
      <Container>
        <Wrapper>
          {i18n('topHeaderOpeningHours', 'Bereikbaar van 09:00 - 18:00 (ma-vrij)')}
          <Tel>
            <a href={`tel:${tel}`} onClick={() => push({ event: 'headerTelephone' })}>
              <Phone icon="phone" />
              {tel}
            </a>
          </Tel>
          <Links>
            <Link to="/support/">{i18n('pageTitleSupport', 'Support')}</Link>
            <OutboundLink target="_blank" href="https://pack.hipex.io/login">{i18n('pageTitleLogin', 'Inloggen')}</OutboundLink>
          </Links>
        </Wrapper>
      </Container>
    </div>
  );
};

export default styled(TopHeader)`
  font-size: 80%;
  display: none;
  position: relative;
  z-index: 1;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: block;
  }

  @media print {
    display: none;
  }
`;

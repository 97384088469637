import React from 'react';
import styled from 'styled-components';
import i18n from '../../i18n';
import Button from '../../components/button';

const StyledButton = styled(Button)`
  && {
    padding: 0.8rem 1.8rem;
  }
`;

const CtaButton = () => (
  <StyledButton
    variant="primaryGradient"
    url={i18n('ctaTryForFreeButtonUrl', '/try-us-free/')}
    analyticsEvent="freeTrialHeader"
  >
    {i18n('ctaTryForFreeButtonText', 'Gratis uitproberen')}
  </StyledButton>
);

export default CtaButton;

import React from 'react';
import styled, { css, createGlobalStyle } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Logo from '../../components/logo';
import Icon from '../icons';
import navigation from '../../navigation';
import NavItem from './nav-item';
import CtaButton from './CtaButton';

const StyledLogo = styled(Logo)`
  fill: ${(props) => props.theme.fontColor};
  width: 10rem;
  margin-top: 0.5rem;
  margin-left: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: none;
  }
`;

const Toggle = styled.button.attrs({ type: 'button' })`
  background: unset;
  border: unset;
  padding: unset;
  cursor: pointer;
  position: absolute;
  top: .5rem;
  right: 1rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    display: none;
  }
  ${Icon} {
    width: 1.5rem;
    color: ${(props) => props.theme.fontColor};
  }
`;

const Ul = styled.ul`
  margin: 0;
  padding: 1rem 0 0 0;
  list-style: none;
  display: block;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    padding: 0;
    display: flex;
    align-items: center;
  }

  @media print {
    height: 4rem;
    li { display: none; }
  }
  
`;

const CtaWrapper = styled.div`
  padding: 1rem;
  text-align: center;
`;

const GlobalStyle = createGlobalStyle`
  html {
    body {
      max-width: 100%;
      overflow-x: hidden;
    }
    ${(props) => props.menuIsOpen && css`
      body {
        overflow: hidden;
        @media all and (min-width: ${props.theme.breakingpoints.xl}) {
          overflow: initial;
        }
      }
    `};
  }
`;

const Nav = ({ className, menuIsOpen, toggleMenuOpen, mobile }) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query NavQuery {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              inMainNav: { eq: true },
              state: {eq: "published"},
            }
          },
          sort: { fields: frontmatter___mainNavOrder },
        ) {
          edges {
            node {
              frontmatter {
                mainNavOrder
                slug
                title
              }
            }
          }
        }
      }
    `,
  );

  // add the static menu items and remodel them to match the gatsby data
  const items = allMarkdownRemark.edges.concat(navigation?.mainNav?.map((item) => ({
    node: {
      frontmatter: item,
    },
  }))).sort((a, b) => (a.node.frontmatter.mainNavOrder > b.node.frontmatter.mainNavOrder ? 1 : -1));

  return (
    <nav className={className}>
      <GlobalStyle menuIsOpen={menuIsOpen} />
      <StyledLogo withGradient />
      <Toggle onClick={toggleMenuOpen} aria-label="Open menu">
        <Icon icon="cross" />
      </Toggle>
      <Ul>
        {items.map(({ node }, index) => (
          <NavItem
            key={`${node.frontmatter.title}-${index}`}
            {...node.frontmatter}
          />
        ))}
      </Ul>
      {mobile && (
        <CtaWrapper>
          <CtaButton />
        </CtaWrapper>
      )}
    </nav>
  );
};

export default styled(Nav)`
  ${(props) => props.menuIsOpen && css`
    /* the dropdown is 300vw, so make sure the overflow is cropped */
    overflow-x: hidden;
  `};
  ${(props) => props.mobile && css`
    overflow: scroll;
    z-index: ${props.theme.zIndex.nav};
    background-color: ${props.theme.white};
    position: fixed;
    top: 0;
    bottom: 0;
    left: -100vw;
    width: 100vw;
    ${props.menuIsOpen && css`
      display: block;
      left: 0;
    `};
    @media all and (min-width: ${props.theme.breakingpoints.xl}) {
      display: none;
    }
  `};
  ${(props) => props.desktop && css`
    display: none;
    @media all and (min-width: ${props.theme.breakingpoints.xl}) {
      display: block;
    }
  `};
`;

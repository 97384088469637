import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import Button from './button';

export const query = graphql`
  fragment ButtonComponent on MarkdownRemarkFrontmatterBlocks {
    button {
      variant
      internalLink {
        frontmatter {
          slug
        }
      }
      externalLink
      text
      targetBlank
      id
    }
  }
  fragment ButtonComponents on MarkdownRemarkFrontmatterBlocks {
    buttons {
      variant
      internalLink {
        frontmatter {
          slug
        }
      }
      externalLink
      text
      targetBlank
      id
    }
  }
`;

const buttonOverBlobUnderText = css`
  margin-top: 1rem;
  position: relative;
  z-index: ${(props) => props.theme.zIndex.overBlob};
`;

const stylePicker = (block) => {
  switch (block) {
    case 'hero':
    case 'text':
    case 'icon-blob-text':
      return buttonOverBlobUnderText;
    default:
      return null;
  }
};

const TemplatedButton = ({ block, button, ...props }) => {
  if (block) {
    return (
      <Button
        css={stylePicker(block)}
        target={button.targetBlank ? '_blank' : '_self'}
        url={button.internalLink?.frontmatter?.slug || button.externalLink}
        variant={button.variant}
        arrow={button.variant === 'secondaryWithArrow'}
        id={button.id}
        {...props}
      >
        {button.text}
      </Button>
    );
  }
  return (
    <Button {...props} />
  );
};

export default styled(TemplatedButton)``;

import { css, createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  html {
    font-size: ${(props) => props.theme.fontSize};
  }

  body {
    background-color: ${(props) => (props.bodyBackground ? props.theme[props.bodyBackground] : props.theme.bodyBackgroundColor)};
    font-family: ${(props) => props.theme.fontFamily};
    font-weight: ${(props) => props.theme.fontWeight};
    line-height: 1.2;
    color: ${(props) => props.theme.fontColor};

    ${(props) => props.usingMouse && css`
      & *:focus {
        outline: none;
      }
    `}
  }

  /* remove default styles */

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: normal;
  }
  p {
    margin: 0;
  }
  address {
    font-style: normal;
  }
  pre {
    font-family: inherit;
  }
  button {
    color: ${(props) => props.theme.fontColor};
  }
  strong {
    font-weight: normal;
  }

  #___gatsby {
    // overflow-x: hidden;
  }
`;

import React, { useState } from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import { Normalize } from 'styled-normalize';
import { Helmet } from 'react-helmet';

import GlobalStyle from '../components/global-style';

import theme from './theme';
import Footer from './footer';
import Header from './header';

import SiteMetadataContext, { useGetSiteMetadata } from '../context/siteMetadataContext';

const TemplateWrapper = ({ children, bodyBackground, pagePosition, meta }) => {
  const siteMetadata = useGetSiteMetadata();
  const {
    name,
    siteUrl,
    title,
    lang,
    locale,
  } = siteMetadata;
  const [usingMouse, setUsingMouse] = useState(false);
  return (
    <ThemeProvider theme={theme}>
      <SiteMetadataContext.Provider value={siteMetadata}>
        <div // eslint-disable-line jsx-a11y/no-static-element-interactions
          onMouseDown={() => setUsingMouse(true)}
          onKeyDown={() => setUsingMouse(false)}
        >
          <Normalize />
          <GlobalStyle usingMouse={usingMouse} bodyBackground={bodyBackground} />
          <Helmet
            title={title}
            titleTemplate={`%s | ${name}`}
          >
            <meta property="og:type" content="website" />
            <meta property="og:locale" content={locale} />
            <meta property="og:url" content={siteUrl} />
            <meta property="og:site_name" content={name} />
            <meta charSet="utf-8" />
            <html lang={lang} />
            <meta name="twitter:card" content="summary_large_image" />
          </Helmet>
          <Header pagePosition={pagePosition} meta={meta} />
          <Main pagePosition={pagePosition}>
            {children}
          </Main>
          <Footer name={name} />
        </div>
      </SiteMetadataContext.Provider>
    </ThemeProvider>
  );
};

const Main = styled.main`
  ${(props) => props.pagePosition === 'spacing' && css`
    padding-top: ${props.theme.sectionSpace};
  `}
`;


export default TemplateWrapper;

import React from 'react';
import styled from 'styled-components';
import Icon from '../icons';
import Container from '../../components/container';
import Logo from '../../components/logo';
import Socials from './socials';
import { useSiteMetadata } from '../../context/siteMetadataContext';
import i18n from '../../i18n';
import navigation from '../../navigation';
import getButtonElement from '../../helpers/get-button-element';
import { push } from '../../analytics';

const A = styled.a``;

const MainFooter = ({ className }) => {
  const {
    address,
    email,
    tel,
  } = useSiteMetadata();

  return (
    <div className={className}>
      <Container>
        <InnerWrapper>
          <div>
            <div>
              <Logo css="width: 240px; fill: white;" />
              <p css="margin: 0.5rem 0;">{i18n('footerTagline', 'Hypersnelle E-commerce Hosting')}</p>
              <Socials variant="footer" />
            </div>

            <div css="line-height: 200%; margin-bottom: 2rem;">
              <Address>
                {address.street} {address.number},
                <br />
                {address.zipcode} {address.city}
              </Address>

              <div css="display: inline-block">
                <ContactLink href={`mailto:${email}`} onClick={() => push({ event: 'footerEmail' })}>
                  <ContactIcon icon="paper-plane" />
                  {email}
                </ContactLink>
                <ContactLink href={`tel:${tel}`} onClick={() => push({ event: 'footerTelephone' })}>
                  <ContactIcon icon="phone" />
                  {tel}
                </ContactLink>
              </div>
            </div>

          </div>
          <Flex>
            {navigation?.footer?.map(({ title, items }) => items?.length > 0 && (
              <Nav key={title}>
                <strong>{title}</strong>
                <ul>
                  {items.map((item) => (
                    <li key={item.href}>
                      <A {...item} {...getButtonElement(item.href)}>{item.title}</A>
                    </li>
                  ))}
                </ul>
              </Nav>
            ))}
          </Flex>
        </InnerWrapper>
      </Container>
    </div>
  );
};

const Flex = styled.div`
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    display: flex;
    > * {
      flex-grow: 1;
    }
  }
  
  @media print {
    display: none;
  }
`;

const ContactIcon = styled(Icon)`
  width: 0.7rem;
  margin-right: .5rem;
`;

const ContactLink = styled.a`
  display: flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  &:hover {
    ${ContactIcon} {
      transform: rotate(10deg);
    }
  }
`;

const Address = styled.address`
  margin-bottom: 2rem;
  margin-right: 2rem;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    display: inline-block;
  }

  @media print {
    margin-right: 0;
  }
`;

const InnerWrapper = styled.div`
  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 2fr;
    grid-gap: calc(${(props) => props.theme.grid.columnGap} * 2);
  }
  @media print {
    padding-top: 4rem;
    display: block;
    color: ${(props) => props.theme.fontColor};
    text-align: center;
  }
`;

const Nav = styled.nav`
  margin-bottom: 2rem;

  strong {
    display: block;
    margin-bottom: 1rem;
    font-family: ${(props) => props.theme.fontFamilyMedium};
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    margin: 0.5rem 0;
    a {
      font-size: 90%;
      color: inherit;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export default styled(MainFooter)`
  background: linear-gradient(90deg, #e91e63, #fc800e);
  margin-top: 2rem;
  padding: 2rem 0;
  color: white;
`;

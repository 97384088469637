import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Container from "../../components/container";
import Emerce from "../../icons/emerce";
import FDGazelle from "../../icons/fd-gazellen";
import Groenzaken from "../../icons/groenzaken";
import Shopware from "../../icons/shopware";
import i18n from "../../i18n";

const SubFooter = ({ className, name }) => (
  <div className={className}>
    <Container>
      <Wrapper>
        <Grid>
          <Icon as={Emerce} />
          <Icon as={FDGazelle} />
          <Icon as={Groenzaken} />
          <Icon as={Shopware} />
        </Grid>
        <div>
          <StyledLink to={i18n("privacyPolicyUrl", "/privacybeleid/")}>
            Privacy
          </StyledLink>
          <StyledLink to={i18n("cookiePolicyUrl", "/cookiebeleid/")}>
            Cookies
          </StyledLink>
          <Copyright>
            Copyright © {name} {new Date().getFullYear()}
          </Copyright>
        </div>
      </Wrapper>
    </Container>
  </div>
);

const Wrapper = styled.div`
  padding: 2rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  > *:not(:last-child) {
    padding-bottom: 2rem;
  }
  @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
    display: flex;
    flex-direction: row;
    > *:not(:last-child) {
      padding-bottom: 0;
    }
  }
`;

const Icon = styled.div`
  height: 66px;
  fill: ${props => props.theme.gray2};
  color: ${props => props.theme.gray2};
`;

const Grid = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr;
  @media all and (min-width: ${props => props.theme.breakingpoints.sm}) {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: ${props => props.theme.grid.columnGap};
  ${Icon} {
    margin: 0 auto;
  }
  @media all and (min-width: ${props => props.theme.breakingpoints.xl}) {
    display: block;
    ${Icon} {
      margin: 0 1rem 0 0;
    }
  }
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.primaryColor};
  text-decoration: none;
  padding: 0 0.5rem;
  @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
    padding: 0 1rem 0 0;
  }
`;

const Copyright = styled.span`
  display: block;
  @media all and (min-width: ${props => props.theme.breakingpoints.lg}) {
    display: inline;
  }
`;

export default styled(SubFooter)`
  color: ${props => props.theme.gray3};
`;

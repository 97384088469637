import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useGetSiteMetadata } from '../../context/siteMetadataContext';

const Switcher = styled.div`
  padding-left: 1em;
`;

const Language = styled.a`
  background: none;
  border: 0;
  color: black;
  font-size: 0.8em;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font-family: ${(props) => (props.active ? props.theme.fontFamilyBold : props.theme.fontFamily)};
`;

const LanguageSwitcher = () => {
  const { lang: currentLang } = useGetSiteMetadata();
  const handleClick = useCallback((e) => {
    // links are replaced by gatsby-plugin-catch-links, so force redirect
    if (!e.currentTarget.href) {
      return;
    }
    window.location = e.currentTarget.href;
    e.preventDefault();
  }, []);

  const languages = [{
    lang: 'nl',
    href: 'https://www.hipex.io/',
  }, {
    lang: 'en',
    href: 'https://www.hipex.io/en/',
  }].map(({ lang, href }, i) => (
    <span key={lang}>
      {i !== 0 && ' | '}
      <Language
        active={currentLang === lang}
        onClick={handleClick}
        href={currentLang === lang ? undefined : href}
        as={currentLang === lang ? 'span' : 'a'}
      >
        {lang.toUpperCase()}
      </Language>
    </span>
  ));

  return (
    <Switcher>
      {languages}
    </Switcher>
  );
};

export default LanguageSwitcher;

import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

export default styled(OutboundLink)`
  border: 2px solid ${(props) => {
    if (props.footer) {
      return 'white';
    }
    return props.color || props.theme.gray3;
  }};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  height: 1.1rem;
  width: 1.1rem;
  color: ${(props) => {
    if (props.footer) {
      return 'white';
    }
    return props.color || props.theme.gray3;
  }};
  margin-left: .5rem;
  display: flex;
  &:hover {
    color: ${(props) => props.theme.fontColor};
    border-color: ${(props) => props.theme.fontColor};
  }
`;

import React from 'react';

export default ({ className }) => (
  <svg
    className={className}
    aria-hidden="true"
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 153.98 65.58"
  >
    <path d="M65.51.33v64.92H.33V.33z" fill="none" stroke="currentColor" />
    <path d="M77.19 21.16h-2.37a.27.27 0 01-.28-.27v-.82a.29.29 0 01.27-.29h6.24a.28.28 0 01.28.28v.82a.27.27 0 01-.27.28h-2.38v8.53a.31.31 0 01-.29.28h-1a.32.32 0 01-.29-.28z" fillRule="evenodd"/>
    <path d="M84.48 23.11a3 3 0 013.08 2.95v.13a2.38 2.38 0 010 .46.3.3 0 01-.32.28h-4.67a2 2 0 002 1.84 2.47 2.47 0 001.62-.57c.14-.1.29-.14.39 0l.39.6c.11.14.18.29 0 .46a3.85 3.85 0 01-2.48.81 3.34 3.34 0 01-3.36-3.5 3.34 3.34 0 013.35-3.46zm1.63 2.76a1.6 1.6 0 00-1.66-1.53 1.71 1.71 0 00-1.81 1.53z" />
    <path d="M92.09 23.11a2.93 2.93 0 012.44 1.17.35.35 0 01-.07.49l-.53.5c-.18.18-.32 0-.43-.07a2 2 0 00-3.5 1.43 2.13 2.13 0 002.11 2.14 2.16 2.16 0 001.57-.77.34.34 0 01.46 0l.46.36c.18.14.25.32.11.49a3.13 3.13 0 01-2.62 1.38 3.54 3.54 0 010-7zm4.1-3a.32.32 0 01.32-.32h.81a.35.35 0 01.31.32v3.68a3.44 3.44 0 012-.71c1.91 0 2.58 1.35 2.58 3.15v3.37a.34.34 0 01-.31.31h-.9a.31.31 0 01-.32-.31v-3.4c0-1.09-.46-1.77-1.34-1.77a2 2 0 00-1.77 1v4.18a.31.31 0 01-.32.31h-.81a.31.31 0 01-.32-.31v-9.49zm8 3.47a.32.32 0 01.32-.32h.35a.27.27 0 01.29.22l.22.57a3 3 0 012.26-.93c2 0 2.58 1.42 2.58 3.15v3.37a.35.35 0 01-.32.32H109a.32.32 0 01-.32-.32v-3.45c0-1.09-.46-1.75-1.31-1.75a2 2 0 00-1.77 1v4.19c0 .22-.1.32-.39.32h-.74a.32.32 0 01-.32-.32v-6.05z" fillRule="evenodd"/>
    <path d="M115.12 23.11a3.46 3.46 0 013.4 3.47 3.42 3.42 0 11-3.4-3.47zm0 5.63a2 2 0 002-2.16 2 2 0 00-4-.24v.24a2.05 2.05 0 002 2.15h.07z" />
    <path d="M120.18 20.1a.32.32 0 01.32-.32h.81a.36.36 0 01.32.32v9.53a.36.36 0 01-.32.32h-.81a.32.32 0 01-.32-.32z" fillRule="evenodd"/>
    <path d="M126.69 23.11a3.42 3.42 0 11-3.43 3.36 3.45 3.45 0 013.43-3.36zm0 5.63a2 2 0 002-2.16 2 2 0 10-4-.35.37.37 0 000 .11v.24a2.08 2.08 0 002 2.16zm11 1.27a2.91 2.91 0 01-3.12 3.15 3.75 3.75 0 01-2.33-.85.32.32 0 01-.07-.39l.35-.64a.29.29 0 01.37-.16.18.18 0 01.09.06 2.73 2.73 0 001.56.63c.89 0 1.66-.42 1.66-1.8v-.36a3.47 3.47 0 01-1.69.5 3.32 3.32 0 01-3.22-3.5 3.26 3.26 0 013.15-3.5 3 3 0 012.12.81l.17-.44c0-.14.08-.24.25-.24h.39a.35.35 0 01.32.31V30zm-3-1.27a2.85 2.85 0 001.56-.54v-2.79a1.9 1.9 0 00-3.54 1.17 2.11 2.11 0 002 2.16z" />
    <path d="M139.07 23.68c-.07-.25 0-.44.28-.44h1a.31.31 0 01.28.18l1.56 4.6 1.88-4.6c.07-.18.18-.18.35-.18h.89a.29.29 0 01.33.22.3.3 0 010 .22l-4.07 9.51c-.07.11-.14.22-.28.22h-.92a.3.3 0 01-.32-.29.25.25 0 010-.17l1.34-3.22z" fillRule="evenodd"/>
    <path d="M74.38 36.77a.3.3 0 01.28-.29h3.23a3.24 3.24 0 01.2 6.48h-2.57v3.39a.29.29 0 01-.28.29h-.56a.29.29 0 01-.29-.28v-9.59zm3.4 5.09A2.15 2.15 0 0080 39.77v-.13a2.07 2.07 0 00-2.19-2.08h-2.33v4.28h2.3zm6.94.53a6.35 6.35 0 011.44.22c0-1.24-.14-1.84-1.08-1.84a5.68 5.68 0 00-2 .39c-.17.07-.24 0-.31-.18l-.15-.39c-.07-.21 0-.28.22-.35a6.08 6.08 0 012.3-.42c1.7 0 2.09.84 2.09 2.83v3.7a.32.32 0 01-.32.32h-.28a.26.26 0 01-.28-.24l-.13-.43a2.92 2.92 0 01-2.09.84A2 2 0 0182 44.62c0-1.34 1-2.23 2.69-2.23zm-.44 3.43a2.35 2.35 0 001.84-.88v-1.38a3.59 3.59 0 00-1.41-.25c-.92 0-1.63.46-1.63 1.31a1.14 1.14 0 001.09 1.2h.11z" />
    <path d="M89.39 40.23a.32.32 0 01.31-.32h.3a.29.29 0 01.29.25l.1.5a2.66 2.66 0 012-.85c.5 0 1.35.07 1.17.53l-.18.53a.27.27 0 01-.31.21h-.08a1.73 1.73 0 00-.7-.18 2.05 2.05 0 00-1.81.85v4.63a.31.31 0 01-.31.32h-.44a.32.32 0 01-.32-.32v-6.12zm5.16.64a.3.3 0 01-.32-.27v-.33a.32.32 0 01.32-.32h.88v-1.88a.35.35 0 01.32-.31h.46a.31.31 0 01.33.31v1.87h2a.33.33 0 01.31.32v.29a.31.31 0 01-.31.32h-2v4c0 .7.22.95.61.95a2.11 2.11 0 001.3-.42c.25-.18.36 0 .44.14l.14.32c.07.22.07.32-.11.43a3.28 3.28 0 01-1.87.53c-1.28 0-1.53-.81-1.53-1.87v-4.08zm5.94-.64a.33.33 0 01.32-.32h.25a.22.22 0 01.25.22l.14.5a3.36 3.36 0 012.26-.85c2 0 2.4 1.27 2.4 3v3.53a.34.34 0 01-.32.32h-.42a.32.32 0 01-.32-.32v-3.67c0-1.27-.42-1.88-1.42-1.88a2.9 2.9 0 00-2 .92v4.64a.35.35 0 01-.32.32h-.42a.32.32 0 01-.32-.32v-6.09z" fillRule="evenodd"/>
    <path d="M111.14 39.77a3 3 0 013 2.87v.18a1.2 1.2 0 010 .38.32.32 0 01-.32.29H109a2.38 2.38 0 002.19 2.3 3 3 0 001.81-.57c.25-.17.32-.22.49 0l.18.25c.14.21.18.28-.07.46a4.11 4.11 0 01-2.44.77 3.26 3.26 0 01-3.26-3.5 3.33 3.33 0 013.29-3.47zm2 2.9a2 2 0 00-2-1.91 2.2 2.2 0 00-2.14 1.91z" />
    <path d="M115.92 40.23a.33.33 0 01.32-.32h.25a.28.28 0 01.28.25l.1.5a2.67 2.67 0 012.06-.85c.49 0 1.34.07 1.17.53l-.18.53a.27.27 0 01-.32.21h-.07a1.47 1.47 0 00-.71-.18 2 2 0 00-1.8.85v4.63a.35.35 0 01-.32.32h-.44a.32.32 0 01-.32-.32v-6.12zm-72.34-.62a27.87 27.87 0 00-6.35-3c-2.92-1.13-6.23-2.42-8.71-4.4a10.5 10.5 0 01-4.19-8.65 9.88 9.88 0 013.86-8c2.83-2.22 7.1-3.4 12.4-3.4a31.86 31.86 0 014.14.27.25.25 0 00.26-.16.26.26 0 00-.06-.3 18.12 18.12 0 10-1.34 28 .28.28 0 000-.37.1.1 0 01-.07 0z" fillRule="evenodd"/>
    <path d="M50.85 23.79a.31.31 0 00-.09-.18c-3.79-3.13-6.84-4.42-10.57-4.42a7.25 7.25 0 00-4.51 1.18 3.4 3.4 0 00-1.35 2.74c0 3.12 3.81 4.54 8.22 6.17a49.72 49.72 0 016.77 2.92.25.25 0 00.22 0 .23.23 0 00.15-.15 18 18 0 001.25-6.6c-.03-.53-.05-1.09-.09-1.66zm-41 28c-.64-.28-1.14-.52-1.14-.94s.33-.56.87-.56a3.45 3.45 0 011.35.3.31.31 0 00.42-.13l.22-.43a.36.36 0 00-.13-.46 3.56 3.56 0 00-1.83-.46c-1.53 0-2.16.94-2.16 1.72 0 1 .8 1.57 1.76 2s1.35.67 1.35 1.09-.3.61-.82.61a3.09 3.09 0 01-1.57-.46.29.29 0 00-.41.08l-.29.6a.26.26 0 00.11.32 3.74 3.74 0 002.07.66 2 2 0 002.24-1.86c0-1.17-.89-1.59-2-2.09z" fillRule="evenodd"/>
    <path d="M46.75 55.69a.34.34 0 00.33-.33v-3.2c0-1.63-.2-2.92-2.31-2.92a7 7 0 00-2.27.37.31.31 0 00-.21.38l.13.55a.29.29 0 00.34.26 6.67 6.67 0 011.83-.3c.82 0 1.09.48 1 1.52a5 5 0 00-1.33-.19c-1.7 0-2.63.91-2.63 2.13 0 1.38.87 1.83 2.11 1.83h2.48l.46-.07zm-1.09-1.18c-.19.33-.91.24-1.52.24s-1-.26-1-.85.59-1.09 1.35-1.09a3.49 3.49 0 011.2.22v1.48zm11.83-5.36a.74.74 0 10-.74-.74.76.76 0 00.74.74zm0-1.39a.63.63 0 11-.63.63.63.63 0 01.63-.63z" />
    <path d="M57.23 48.78h.07v-.28h.15l.16.28h.11l-.16-.28a.27.27 0 00.16-.22.24.24 0 00-.24-.24h-.29v.74zm.11-.65h.2a.13.13 0 010 .26h-.2z" />
    <path d="M16 49.15a3.1 3.1 0 00-1.83.66V47a.34.34 0 00-.31-.3h-.76a.32.32 0 00-.31.3v8.48a.31.31 0 00.29.31h.76a.28.28 0 00.3-.27v-4.17a1.84 1.84 0 011.66-.91c.83 0 1.26.61 1.26 1.65v3.36a.3.3 0 00.3.3h.77a.31.31 0 00.31-.3v-3.33c0-1.72-.66-3-2.44-3z" fillRule="evenodd"/>
    <path d="M32 52.2c-.07-2.15-1-3.05-3-3.05a9.3 9.3 0 00-2.35.37c-.22.09-.37.2-.37.35v8.06a.29.29 0 00.29.31h.65a.31.31 0 00.28-.24v-2.33a13.07 13.07 0 002 .11c1.46-.05 2.44-1.11 2.53-2.9v-.81.13zm-1.33.66c0 1.22-.37 1.76-1.4 1.81-.5 0-1.7-.11-1.7-.11s-.08-.05-.08-.09v-3.86a.05.05 0 01.05-.06 6.63 6.63 0 011.4-.18c1.24 0 1.68.18 1.72 1.77a4.33 4.33 0 010 .72z" />
    <path d="M41 49.28h-.57c-.17 0-.39 0-.48.2l-1 3.64-1.7-3.81c0-.09-.13-.16-.29-.16h-.08a.29.29 0 00-.26.16l-1.7 3.81-1-3.64a.3.3 0 00-.28-.2h-.94a.22.22 0 00-.26.17.2.2 0 000 .18l1.83 5.95a.28.28 0 00.26.18h.09a.23.23 0 00.24-.16l1.94-4.29 1.92 4.29a.28.28 0 00.28.16.28.28 0 00.29-.18l1.81-5.95c.25-.19.12-.35-.1-.35zm10.07-.06h-1.2c-.57 0-.9.07-1.13.09h-.33a.43.43 0 00-.26.13.19.19 0 00-.07.15v5.8a.31.31 0 00.29.31H49c.26 0 .43 0 .43-.31v-4.75s.07-.09.09-.11a3 3 0 011.26-.16H51a3 3 0 01.52.11.27.27 0 00.36-.11l.33-.54c.19-.44-.55-.61-1.14-.61z" fillRule="evenodd"/>
    <path d="M55.38 49.15a3.31 3.31 0 000 6.61 3.69 3.69 0 002.35-.76c.2-.17.14-.28 0-.41l-.34-.59c-.11-.17-.24-.13-.37 0a2.38 2.38 0 01-1.55.54 1.82 1.82 0 01-1.88-1.72H58a.3.3 0 00.29-.28v-.44a2.81 2.81 0 00-2.88-2.92zm-1.72 2.6a1.61 1.61 0 011.7-1.44 1.47 1.47 0 011.54 1.4zm-28.55-.66a3.43 3.43 0 00-.58-1 2.79 2.79 0 00-.92-.68 3.56 3.56 0 00-2.57 0 2.79 2.79 0 00-.92.68 2.61 2.61 0 00-.58 1 4.08 4.08 0 00.58 3.75 2.79 2.79 0 00.92.68 3.5 3.5 0 001.28.24 3.78 3.78 0 001.29-.24 2.79 2.79 0 00.92-.68 2.65 2.65 0 00.58-1.05 4.25 4.25 0 000-2.7zm-2.81 3.58c-1.13 0-1.72-1-1.72-2.16s.57-2.16 1.72-2.16 1.72 1 1.72 2.16-.58 2.16-1.72 2.16z" />
    <path d="M65.52 65.25h88.14V.33H65.52" fill="none" stroke="currentColor" />
  </svg>
);

import React from 'react';
import styled from 'styled-components';

import MainFooter from './main-footer';
import SubFooter from './sub-footer';

const Footer = () => {

  const isBrowser = () => typeof window !== 'undefined';

  const hideFooter = isBrowser()
    && window.location
    && window.location.pathname.includes('performance-scan/compare');

  if (hideFooter) {
    return null;
  }

  return (
    <FooterWrapper>
      <MainFooter />
      <SubFooter />
    </FooterWrapper>
  );
};

const FooterWrapper = styled.footer`
  @media print {
    break-before: page;
    break-inside: avoid;
  }
`;

export default Footer;

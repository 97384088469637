export function push(data) {
  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  if (!data.event) { return; }

  window.dataLayer.push({
    url: window.location.href,
    ...data,
  });
}

export default { push };

import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import getButtonElement from '../../../helpers/get-button-element';

export const activeClassName = 'active';

export default function DropdownList({
  hasIcon, child, items = [],
}) {
  if (!child) {
    return <List />;
  }

  return (
    <List single={items.length === 1}>
      {items.length > 1
        ? <DropdownListTitle>{child.title}</DropdownListTitle>
        : null}
      <Ul hasIcon={hasIcon}>
        {(child.items || []).map((item, itemIndex) => {
          const childlink = item.slug || item.href;
          const childprops = getButtonElement(childlink);
          return (
            <GridLi
              key={`${item.title}-${itemIndex}`}
              hasIcon={hasIcon}
            >
              <StyledLink
                {...{
                  ...childprops,
                  // we are only allowed to pass activeClassName on Link
                  // (that has a to from the props)
                  ...(childprops.to && { activeClassName }),
                }}
                className={item.icon ? 'hasIcon' : ''}
                disabled={item.disabled ?? false}
              >
                {item.icon && <GridIcon src={item.icon} alt={item.iconAlt} />}
                {!item.icon && <span style={{ 'grid-row': '1/3' }} />}
                <StyledLinkText className={item.icon ? 'hasIcon' : ''}>
                  <GridTitle>{item.title}</GridTitle>
                  <GridDescription>{item.description}</GridDescription>
                </StyledLinkText>
              </StyledLink>
            </GridLi>
          );
        })}
      </Ul>

    </List>

  );
}

export const StyledLinkText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 30px;
  font-family: ${(props) => props.theme.fontFamilyLight};
  &.hasIcon {
    margin-left: .5rem;
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    height: 50px;
    margin-left: 0;
    font-family: ${(props) => (props.light ? props.theme.fontFamily : props.theme.fontFamilyMedium)};
    &.hasIcon {
      margin-left: 1rem;
    }
  }
`;

export const GridIcon = styled.img`
  height: 30px;
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    height: ${(props) => (props.size ? `${props.size}px` : '50px')};
    width: ${(props) => (props.size ? `${props.size}px` : '50px')};
  }
`;

export const StyledLink = styled(Link)`
  display: block;
  color: inherit;
  font-size: .9rem;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  text-decoration: none;
  cursor: pointer;
  
  width: 95%;
  
  &:hover {
    background-color: ${(props) => props.theme.gray1};
    border-radius: 50px;
    padding: .75rem 2.5rem .75rem 1.5rem;
    margin: -.75rem 0rem -.75rem -1.5rem;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    &:hover {
      background-color: ${(props) => props.theme.gray1};
      border-radius: 50px;
      padding: .5rem 2.5rem .5rem 1.75rem;
      margin: 0;
      margin-left: -1.75rem;
    }
    
    &.hasIcon:hover {
      padding: .5rem 2.5rem .5rem .75rem;
      margin-left: -.75rem;
    }
  }
  
  @media all and (max-width: ${(props) => props.theme.breakingpoints.md}) {
    display: inline-flex;
    width: 100%;
    align-items: center;
  }
  
  &.${activeClassName} {
    color: ${(props) => props.theme.primaryColor};
  }
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    padding: 0.5rem 0;
  }
`;

export const GridTitle = styled.span`
  color: ${(props) => props.theme.fontColor}
`;

const GridDescription = styled.span`
  margin-top: 0.3rem;
  max-width: 21.875rem;
  color: ${(props) => props.theme.secondaryFontColor}
  font-family: ${(props) => props.theme.fontFamilyLight}
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    font-family: ${(props) => props.theme.fontFamilyMedium}
  }
`;

const GridLi = styled.li`
  padding: 0.5rem 0;
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    padding: 0.2rem 0;
  }
  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    text-align: left;
    ${StyledLink} {
      &.hasIcon {
        display: inline-grid;
        grid-auto-rows: auto;
        grid-template-columns: auto;
        ${(props) => props.hasIcon && css`
          grid-template-columns: auto 1fr;
        `}
        align-items: center;
      }
    }
    
    ${GridIcon} {
      grid-row: 1 / 3;
    }
    ${GridTitle} {
      margin-top: auto;
    }
    ${GridDescription} {
      margin-bottom: auto;
      font-size: 80%;
      color: ${(props) => props.theme.secondaryFontColor};
    }
  }

  ${GridDescription} {
    display none;
    @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
      display: block;
    }
  }
`;

const DropdownListTitle = styled.div`
  z-index: 999;
  font-family: ${(props) => props.theme.fontFamilyMedium};
  display: flex;
  flex-direction: column;
  font-size: .9rem;
  
  ::after {
    margin-top: .5rem;
    width: 29px;
    height: 2px;
    background-color: #d8d8d8;
    content: '';
  }
`;

const Ul = styled.ul`
  padding: .5rem 0 .5rem;
  list-style: unset;
  display: inline-block;
  position: relative;
  margin-left: 0.5rem;
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.md}) {
    margin-left: 0;
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  
  margin-left: 5px;
  margin-right: 5px;
  
  width: 100%;
  flex-grow: 1;
  
  ${(props) => props.single && css`
    padding-left: ${props.theme.grid.containerGap};
    padding-right: ${props.theme.grid.containerGap};
  `}
  
  @media all and (min-width: ${(props) => props.theme.breakingpoints.sm}) {
    margin-left: 20px;
    margin-right: 20px;
    
    ${(props) => props.single && css`
      position: absolute;
      background-color: white;
      max-width: 300px !important;
      border-radius: 25px;
      margin-left: 1rem;
      padding-left: 0;
      padding-right: 0;

      &::before {
        background-color: white;
        display: block;
        position: absolute;
        content: '';
        width: 300vw;
        height: 100%;
        margin-left: -100vw;
        border-top: 2px solid rgba(0, 0, 0, 0.2);
        box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 10px;
      }
    `}
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.lg}) {
    margin-left: 50px;
    margin-right: 50px;
    ${(props) => props.single && css`
      margin-left: 1rem;
    `}
  }

  @media all and (min-width: ${(props) => props.theme.breakingpoints.xl}) {
    max-width: ${(props) => (props.small ? props.theme.breakingpoints.lg : props.theme.breakingpoints.xl)}
  }
  
  @media all and (max-width: ${(props) => props.theme.breakingpoints.md}) {
    margin: 0;
  }
  
  ${Ul} {
    &::before {
      border-right: 1px solid #000000;
      padding-right: 10px;
      z-index: 999;
    }
  }
  
  :last-child {
    ${Ul} {
      &::before {
          border: none;
      }
    }
  }
`;
